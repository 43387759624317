import React, {Component} from "react";
import {connect} from "react-redux";
import * as W29F2012Actions from "../../../../redux/W2X/W29F2012/W29F2012_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Combo, TextField} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Grow from "@material-ui/core/Fade";
import Modal from "../../../common/modal/modal";
import _ from "lodash";
import Api from "../../../../services/api";
import CDN from "../../../CDN";
import Typography from "@material-ui/core/Typography";
import InputMask from "react-input-mask";
import {FormControl, FormHelperText, TextField as TextFieldM} from "@material-ui/core";

const styles = {
    shiftName: {
        "@media only screen and (max-width: 1024px)": {
            marginTop: 37,
            marginBottom: 0,
        },
    },
    rmUnderLine: {
        "& .MuiInput-underline:before": {
            content: "none",
        },
        "& .MuiInput-underline:after": {
            content: "none",
        },
    },
};

class W29F2012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingCboEmployees: false,
            loadingCboOtType: false,
            loadingCheckTime: true,
            disabled: false,
            loading: false,
            uploading: false,
            dataHover: null,
            dataForm: {
                TransID: "",
                employee: null,
                AttendanceDate: null,
                OTFrom: null,
                OTTo: null,
                OTHour: 0,
                ShiftName: "",
                ShiftID: "",
                Reason: "",
                OTTypeID: "",
                IsOverTime: "",
                WorkDayType: "",
            },
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            isUseOTType: 0,
            dataOldAttachments: [],
            errorMask: false,
            errorMaskTwo: false,
            error: {},
        };

        this.attachments = [];
        this.deletedFile = [];
        this.defaultParams = {
            FormID: "W29F2012",
            Language: Config.language || "84",
        };
        this.filterCboEmployees = {
            timer: null,
            search: "",
            skip: 0,
            limit: 20,
        };
        this.setCursorOTFrom = false;
        this.setCursorOTTo = false;
        this.setTypeOTFrom = false;
        this.setTypeOTTo = false;
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const employeeSelected = _.get(nextProps.getCboEmployees, "rows[0]", {});
        if (employeeSelected.EmployeeID !== prevState.dataForm.EmployeeID && nextProps.mode === "add") {
            return {dataForm: {...prevState.dataForm, employee: employeeSelected}};
        } else {
            return null;
        }
    };

    createDateFromTextValue = (value) => {
        if (!value) return null;
        const splitParts = value.split(":");
        return new Date(1970, 1, 1, splitParts[0], splitParts[1]);
    };

    loadForm = () => {
        const {Language} = this.defaultParams;
        const params = {
            TransID: _.get(this.props.data, "TransID", ""),
            Language,
        };
        this.setState({loading: true});
        this.props.w29f2012Actions.getForm(params, (err, data) => {
            if (err) Config.popup.show("ERROR", err);
            if (data) {
                let getUser = Config.getUser({EmployeeID: _.get(data, "EmployeeID", "")});
                const otFrom = _.get(data, "OTFrom", "");
                const otTo = _.get(data, "OTTo", "");
                const oTHour = _.get(data, "OTHour", 0);
                const propOtForm = _.get(this.props.data, "OTFrom", "");
                const propOtTo = _.get(this.props.data, "OTTo", "");
                const attendanceDate = _.get(data, "AttendanceDate", null);
                const propAttendanceDate = _.get(this.props.data, "AttendanceDate", null);
                const oTTypeID = _.get(data, "OTTypeID", false);
                this.setState({
                    loadingCheckTime: _.isNil(attendanceDate) || _.isNil(propAttendanceDate),
                    dataForm: {
                        ..._.omit(data, ["AppStatusID"]),
                        employee: _.pick(getUser ? getUser : this.props.data, ["EmployeeID", "EmployeeName", "UserPictureURL"]),
                        OTFrom: otFrom ? otFrom : propOtForm,
                        OTTo: otTo ? otTo : propOtTo,
                        OTHour: oTHour ? oTHour : _.get(this.props.data, "OTHour", 0),
                        OTTypeID: oTTypeID ? oTTypeID : _.get(this.props.data, "OTTypeID", ""),
                        AttendanceDate: attendanceDate ? attendanceDate : propAttendanceDate,
                    },
                });
            }
            this.setState({loading: false});
        });
    };

    loadCboOtType = (param = {}) => {
        const {mode, getForm} = this.props;
        const {dataForm} = this.state;
        const {Language} = this.defaultParams;
        let oTTo = null;
        let oTFrom = null;
        let employeeID = "";
        let attendanceDate = null;
        if (mode === "edit" || mode === "view") {
            oTTo = _.get(getForm, "OTTo", null);
            oTFrom = _.get(getForm, "OTFrom", null);
            employeeID = _.get(getForm, "EmployeeID", "");
            attendanceDate = _.get(getForm, "AttendanceDate", null);
        }
        if (mode === "add") {
            oTTo = _.get(param, "OTTo", null);
            oTFrom = _.get(param, "OTFrom", null);
            employeeID = _.get(dataForm, "employee.EmployeeID", "");
            attendanceDate = _.get(dataForm, "AttendanceDate", null);
        }

        const params = {
            Language,
            ShiftID: _.get(param, "ShiftID", ""),
            OTFrom: oTFrom,
            OTTo: oTTo,
            EmployeeID: employeeID,
            AttendanceDate: attendanceDate,
        };
        this.setState({loadingCboOtType: true});
        this.props.w29f2012Actions.getCboOtType(params, (err) => {
            this.setState({loadingCboOtType: false});
            if (err) Config.popup.show("ERROR", err);
        });
    };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W29F2012",
            HostID: "",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({loadingCboEmployees: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({loadingCboEmployees: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total,
                    },
                });
            }
        });
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    loadAttachments = () => {
        const {data} = this.props;
        const param = {
            KeyID: _.get(data, "TransID", ""),
        };
        this.setState({loading: false});
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
            this.setState({loading: false});
        });
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onStore = async () => {
        const {dataForm, error} = this.state;
        const {mode, data, onClose} = this.props;
        const arrAttachments = this._getAttachments();
        let params = {
            DivisionID: Config.getDivisionID(),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            TransID: _.get(data, "TransID", ""),
            EmployeeID: _.get(dataForm, "employee.EmployeeID", ""),
            ..._.omit(dataForm, ["employee"]),
            OTFrom: dataForm.OTFrom,
            OTTo: dataForm.OTTo,
            arrAttachment: JSON.stringify(arrAttachments),
        };
        if(dataForm.OTFrom && dataForm.OTFrom.includes("_")) {
            return;
        }
        if(dataForm.OTTo && dataForm.OTTo.includes("_")) {
            return;
        }
        this.setState({loading: true});
        const res = await Api.put(`/w29f2012/${mode}`, params);
        const isSuccess = !_.get(res, "data.Status", 1);
        const messageErr = _.get(res, "message", false);
        const checkErr = Object.values(error).every(x => x === "");
        this.setState({loading: false});
        if (messageErr) {
            Config.popup.show("ERROR", messageErr);
            return;
        }
        if (!checkErr) {
            return false;
        }
        if (isSuccess) {
            const mSuccess =
                mode === "add" ? Config.lang("Dang_ky_tang_ca_thanh_cong") : Config.lang("Chinh_sua_tang_ca_thanh_cong");
            Config.notify.show("success", mSuccess, 2000);
            this.setState({disabled: true});
            this._removeCDN();
            onClose(true)

        } else {
            if(_.get(res, "data.MsgAsk", "") === 0) {
                Config.popup.show("INFO", _.get(res, "data.Message", ""));
            }
            if(_.get(res, "data.MsgAsk", "") === 1) {
                Config.popup.show('YES_NO', _.get(res, "data.Message", "") , () => {
                    params = {...params, code: _.get(res, "data.code", "")};
                    this._saveData(params, mode);
                });
            }
        }
    };

    _saveData = async (params, mode) => {
        const {onClose} = this.props;
        this.setState({loading: true});
        const res = await Api.put(`/w29f2012/confirm-${mode}`, params);
        const messages = _.get(res, "data.Message", Config.lang("Loi_chua_xac_dinh"));
        const isSuccess = !_.get(res, "data.Status", 1);
        const messageErr = _.get(res, "message", false);
        this.setState({loading: false});
        if (messageErr) {
            Config.popup.show("ERROR", messageErr);
            return;
        }
        if (isSuccess) {
            const mSuccess =
                mode === "add" ? Config.lang("Dang_ky_tang_ca_thanh_cong") : Config.lang("Chinh_sua_tang_ca_thanh_cong");
            Config.notify.show("success", mSuccess, 2000);
            this.setState({disabled: true});
            this._removeCDN();
            onClose(true)

        } else {
            Config.popup.show("INFO", messages);
        }
    };
    checkOTType = () => {
        Api.get("/w29f2012/check-use-ottype").then((res) => {
            this.setState({isUseOTType: _.get(res, "data.IsUseOTType", 0)});
        });
    };

    checkStore = async (typeCheck, param) => {
        const {dataForm} = this.state;
        const {Language} = this.defaultParams;
        const oTFrom = _.get(param, "OTFrom", false);
        const oTTo = _.get(param, "OTTo", false);
        const attendanceDate = _.get(param, "AttendanceDate", false);
        const shiftID = _.get(dataForm, "ShiftID", false);
        const employeeID = _.get(dataForm, "employee.EmployeeID", "");
        let params = {
            Language,
            EmployeeID: employeeID,
            ShiftID: shiftID ? shiftID : _.get(param, "ShiftID", ""),
            AttendanceDate: attendanceDate ? attendanceDate : _.get(dataForm, "AttendanceDate", null),
            OTFrom: oTFrom ? oTFrom : null,
            OTTo: oTTo ? oTTo : null,
        };

        const res = await Api.put(`/w29f2012/${typeCheck}`, params);
        const data = _.get(res, "data", {});
        const isValid = !_.get(data, "Status", 1);
        const message = _.get(data, "Message", Config.lang("Loi_chua_xac_dinh"));
        return {isValid, data, message};
    };

    setStateErrorText = (value) => {
        this.setState({
            error: {
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    swap = (arr,x,y) => {
        let tmp = arr[x];
        arr[x] = arr[y];
        arr[y] = tmp;
        return arr;
    };

    getDataTime = (key,data) => {
        if(!key || !data) return false;
        let arr = data.split("");
        if(arr && arr.length > 0) {
            if(Number(arr[0]) >= 3){
                arr = this.swap(arr,0,1);
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            } else {
                for(let i = 0; i < arr.length; i++){
                    arr[i] = arr[i] === "_" ? "0" : arr[i];
                }
            }
        }

        if(key === "OTFrom") {
            return arr.join('')
        }
        if(key === "OTTo") {
            return arr.join('')
        }
    };

    checkChangeTime = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 0) {
                return !isNaN(a);
            }
            return isNaN(a);
        })
    };

    CheckDataWhenSelection = (data) => {
        let test = data.split('');
        return test.every((a, i) => {
            if (i === 2) {
                return typeof (a) === 'string'
            }
            return !isNaN(a);

        })};

    moveCursor = (id, data) => {
        let check = false;
        if(data.charAt(0) <= 2 && data.charAt(1) <= 2 && this.CheckDataWhenSelection(data)) {
            check = true;
        }
        if(id === "cursorOTFrom") {
            this.setCursorOTFrom = true;
        }
        if(id === "cursorOTTo") {
            this.setCursorOTTo = true;
        }
        let el = document.getElementById(id);
        el.setSelectionRange(check ? 1 : 3,check ? 1 : 3);
    };

    checkOtFromEmpty = (data) => {
        if(!data) return;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                this.setCursorOTFrom = false;
                this.setTypeOTFrom = false;
            }
        }
    };

    checkOttoEmpty = (data) => {
        if(!data) return;
        if(data.includes("_")) {
            const arr = data.split('');
            const dataFilter = arr.filter(item => item === "_");
            if(dataFilter.length >= 4) {
                this.setCursorOTTo = false;
                this.setTypeOTTo = false;
            }
        }
    };



    handleChange = async (key, e) => {
        const {dataForm} = this.state;
        const selection = window.getSelection();
        let value = _.get(e, "target.value", _.get(e, "value", e));
        let stateChange = {...dataForm};
        switch (key) {
            case "OTFrom": {
                let checkData = value.split('');
                if (!checkData.some(val => val === "_")) {
                    this.setStateErrorText({errorMask: ""});
                }
                if(value.charAt(0) > 2 && this.CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !this.setTypeOTFrom && this.checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "cursorOTFrom") {
                        stateChange =  {...dataForm, [key]: await this.getDataTime(key,value)};
                        this.setCursorOTFrom = false;

                    }
                } else {
                    stateChange =  {...dataForm, [key]: value};
                    this.setTypeOTFrom = true;
                }
                break;
            }
            case "OTTo": {
                let checkData = value.split('');
                if (!checkData.some(val => val === "_")) {
                    this.setStateErrorText({errorMask: ""});
                }

                if(value.charAt(0) > 2 && this.CheckDataWhenSelection(value)) {
                    return;
                }
                if(selection && !this.setTypeOTTo && this.checkChangeTime(value)) {
                    if(selection.focusNode.childNodes[0].id === "cursorOTTo") {
                        stateChange =  {...dataForm, [key]: await this.getDataTime(key,value)};
                        this.setCursorOTTo = false
                    }
                } else {
                    stateChange =  {...dataForm, [key]: value};
                    this.setTypeOTTo = true;
                }
                break;
            }
            case "EmployeeID": {
                stateChange = {
                    ...dataForm,
                    employee: {...dataForm.employee, [key]: _.get(value, "EmployeeID", "")}
                };
                break;
            }
            case "AttendanceDate": {
                const result = await this.checkStore("check-shift", {AttendanceDate: value});
                this.setState({loadingCheckTime: true});
                if (result.isValid) {
                    this.setState({loadingCheckTime: false});
                    stateChange = {
                        ...dataForm,
                        [key]: value,
                        OTFrom: null,
                        OTTo: null,
                        OTHour: 0,
                        ShiftName: _.get(result, "data.ShiftName", ""),
                        ShiftID: _.get(result, "data.ShiftID", ""),
                        WorkDayType: _.get(result, "data.WorkDayType", ""),
                    };
                } else {
                    stateChange = {
                        ...dataForm,
                        ShiftName: "",
                        ShiftID: "",
                    };
                    Config.popup.show("INFO", result.message);
                }
                break;
            }
            default:
                stateChange = {
                    ...dataForm,
                    [key]: value
                };
                break;
        }

        //move focus input OTFrom
        if(key === "OTFrom" && !this.setCursorOTFrom && stateChange.OTFrom && !stateChange.OTFrom.includes("_")) {
            setTimeout(() => {
                this.moveCursor('cursorOTFrom',stateChange.OTFrom)
            },100)
        }

        //move focus input OTTo
        if(key === "OTTo" && !this.setCursorOTTo && stateChange.OTTo && !stateChange.OTTo.includes("_")) {
            setTimeout(() => {
                this.moveCursor('cursorOTTo',stateChange.OTTo)
            }, 100)
        }
        this.setState({dataForm: stateChange}, () => {
            this.checkOtFromEmpty(this.state.dataForm?.OTFrom);
            this.checkOttoEmpty(this.state.dataForm?.OTTo);
        });
    };

    _checkMaskRules = (data) => {
        if (!data) return null;
        return data?.startsWith('2');
    };

    loadApiCheckTime = async (key) => {
        const { dataForm } = this.state;
        if(dataForm.OTFrom && !dataForm.OTFrom.includes("_") && dataForm.OTTo && !dataForm.OTTo.includes("_")) {
            let stateChange = {};
            this.setState({loading: true});
            const params = {
                OTFrom: _.get(dataForm, "OTFrom", null),
                OTTo :  _.get(dataForm, "OTTo", null)
            };
            const result = await this.checkStore("check-time", params);
            this.setState({loading: false});
            if (result.isValid) {
                stateChange = {
                    ...dataForm,
                    // [key]: value,
                    OTHour: _.get(result, "data.OTHour", 0),
                    IsOverTime: _.get(result, "data.IsOverTime", 0),
                };
                this.setCursorOTFrom = false;
                this.setCursorOTTo = false;
                this.loadCboOtType({...params, ShiftID: _.get(dataForm, "ShiftID", "")});
                this.setState({dataForm: stateChange});
            } else {
                this.setState({
                    dataForm: {
                        ...dataForm,
                        [key]: null
                    },
                    error: {}
                });
                Config.popup.show("INFO", result.message);
            }
        }

    };

    render() {
        const {
            loadingCboEmployees,
            loadingCboOtType,
            loadingCheckTime,
            loading,
            uploading,
            disabled,
            dataForm,
            dataOldAttachments,
            dataCboEmployees,
            isUseOTType,
            error,
        } = this.state;
        const checkRuleOTFrom = dataForm.OTFrom && this._checkMaskRules(dataForm.OTFrom);
        const checkRuleOTTo = dataForm.OTTo && this._checkMaskRules(dataForm.OTTo);
        const {mode, getCboOtType, classes} = this.props;
        const allowSave = dataForm.AttendanceDate && dataForm.OTFrom && dataForm.OTTo;
        const isView = mode === "view";
        const isEdit = mode === "edit";
        const cbDataSource = isView || isEdit ? [dataForm.employee] : dataCboEmployees;
        const shiftName = _.get(dataForm, "ShiftName", "");
        return (
            <>
                <Modal.Content>
                    <Row>
                        <Col xs={12} sm={6}>
                            <Combo.Employees
                                dataSource={cbDataSource}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                acceptCustomValue={true}
                                displayExpr={"EmployeeName"}
                                keyExpr={"EmployeeID"}
                                valueExpr={"EmployeeID"}
                                subTextExpr={"EmployeeID"}
                                value={dataForm.employee}
                                FormID={"W29F2012"}
                                stylingMode={"underlined"}
                                shrink={true}
                                required={true}
                                dropdownProps={{
                                    readOnly: true,
                                }}
                                loading={loadingCboEmployees}
                                disabled={true}
                                label={Config.lang("Nhan_vien")}
                                onValueChanged={(e) => this.handleChange("EmployeeID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.filterCboEmployees.skip = 0;
                                    this.loadCboEmployees(true);
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                        <Grow in={Boolean(shiftName)} timeout={1500}>
                            <Col xs={12} sm={6}>
                                <Typography
                                    style={{
                                        marginTop: Boolean(shiftName) ? (Config.isMobile ? 10 : 35) : 0,
                                        transition: "0.6s",
                                    }}
                                    variant="subtitle1"
                                >
                                    {shiftName}
                                </Typography>
                            </Col>
                        </Grow>
                    </Row>
                    <Row>
                        <Col sm={6} xs={12}>
                            <DateBoxPicker
                                height={28}
                                useMaskBehavior={true}
                                label={Config.lang("Ngay")}
                                width={"100%"}
                                stylingMode={"underlined"}
                                shrink={true}
                                InputLabelProps={{style: {marginTop: "-7px"}}}
                                value={dataForm.AttendanceDate}
                                onValueChanged={(e) => this.handleChange("AttendanceDate", e)}
                                margin={"normal"}
                                disabled={loading}
                                // dateBoxProps={{
                                //     readOnly: isView,
                                // }}
                                required={true}
                            />
                        </Col>
                        <Col sm={4} xs={12}>
                            <FormControl variant={"standard"}
                                         error={error && !!error["errorMask"]}
                                         fullWidth={true}>
                                <Row>
                                    <Col sm={6} xs={6}>
                                        <InputMask
                                            mask={'12:34'}
                                            maskChar={loadingCheckTime || loading ? "" : "_"}
                                            alwaysShowMask={true}
                                            formatChars={{
                                                '1': '[0-9]',
                                                '2': checkRuleOTFrom ? '[0-3]' : '[0-9]',
                                                '3': '[0-5]',
                                                '4': '[0-9]',
                                            }}
                                            style={{marginTop: 14}}
                                            onBlur={() => {
                                                if (dataForm.OTFrom) {
                                                    if (dataForm.OTFrom.split('').some(val => val === "_")) {
                                                        this.setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                    } else {
                                                        this.setStateErrorText({errorMask: ""});
                                                    }
                                                }
                                                this.loadApiCheckTime("OTFrom");

                                            }}
                                            onKeyDown={(e) => {
                                                if(e?.keyCode === 9) {
                                                    this.loadApiCheckTime("OTFrom");
                                                }
                                            }}
                                            disabled={loadingCheckTime || loading}
                                            value={dataForm.OTFrom || ""}
                                            onChange={(e) => this.handleChange("OTFrom", e)}
                                        >
                                            {(props) => <TextFieldM {...props}
                                                                   label={Config.lang("Gio_tang_ca_tu")}
                                                                   InputLabelProps={{shrink: true}}
                                                                   InputProps={{readOnly: isView}}
                                                                   margin={"normal"}
                                                                   required={true}
                                                                   inputProps={{
                                                                       id: "cursorOTFrom",
                                                                       onMouseUp: () => {
                                                                           if(window.getSelection()) {
                                                                               this.setTypeOTFrom = false;
                                                                           }
                                                                       }
                                                                   }}
                                                                   className={classes.inputMask}
                                                                   variant={"standard"}/>}
                                        </InputMask>
                                    </Col>
                                    <Col sm={6} xs={6}>
                                        <InputMask
                                            mask={'12:34'}
                                            maskChar={loadingCheckTime || loading ? "" : "_"}
                                            alwaysShowMask={true}
                                            formatChars={{
                                                '1': '[0-9]',
                                                '2': checkRuleOTTo ? '[0-3]' : '[0-9]',
                                                '3': '[0-5]',
                                                '4': '[0-9]',
                                            }}
                                            style={{marginTop: 14}}
                                            disabled={loadingCheckTime || loading}
                                            onBlur={() => {
                                                if (dataForm.OTTo) {
                                                    if (dataForm.OTTo.split('').some(val => val === "_")) {
                                                        this.setStateErrorText({errorMask: Config.lang('Ban_phai_nhap_dung_dinh_dang_vd_11:11')});
                                                    } else {
                                                        this.setStateErrorText({errorMask: ""});
                                                    }
                                                }
                                                this.loadApiCheckTime("OTTo");
                                            }}
                                            onKeyDown={(e) => {
                                                if(e?.keyCode === 9) {
                                                    this.loadApiCheckTime("OTTo");
                                                }
                                            }}
                                            value={dataForm.OTTo || ""}
                                            onChange={(e) => this.handleChange("OTTo", e)}
                                        >
                                            {(props) => <TextFieldM {...props}
                                                                   InputProps={{readOnly: isView}}
                                                                   label={Config.lang("Gio_tang_ca_den")}
                                                                   InputLabelProps={{shrink: true}}
                                                                   required={true}
                                                                   margin={"normal"}
                                                                   inputProps={{
                                                                       id: "cursorOTTo",
                                                                       onMouseUp: () => {
                                                                           if(window.getSelection()) {
                                                                               this.setTypeOTTo = false;
                                                                           }
                                                                       }
                                                                   }}
                                                                   className={classes.inputMask}
                                                                   variant={"standard"}/>}
                                        </InputMask>
                                    </Col>
                                </Row>
                                <FormHelperText
                                    style={{left: "20%"}}>{error && error["errorMask"]} </FormHelperText>
                            </FormControl>
                        </Col>
                        <Col sm={2} xs={12}>
                            <TextField
                                label={Config.isMobile ? Config.lang("So_gio_tang_ca") : " "}
                                variant={"standard"}
                                margin={"normal"}
                                value={`${dataForm.OTHour} h` || ""}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    readOnly: true,
                                }}
                                className={classes.rmUnderLine}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TextField
                                label={Config.lang("Ly_do_tang_ca")}
                                variant={"standard"}
                                margin={"normal"}
                                value={dataForm.Reason || ""}
                                disabled={loading}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    readOnly: isView,
                                }}
                                onChange={(e) => this.handleChange("Reason", e)}
                                fullWidth
                            />
                        </Col>
                    </Row>
                    {isUseOTType ? (
                        <Row>
                            <Col sm={6} xs={12}>
                                <Combo
                                    selectProps={{
                                        readOnly: isView,
                                    }}
                                    dataSource={getCboOtType}
                                    displayExpr={"OTTypeName"}
                                    valueExpr={"OTTypeID"}
                                    stylingMode={"underlined"}
                                    disabled={loading || !dataForm.OTHour}
                                    loading={loadingCboOtType}
                                    searchEnabled={true}
                                    shrink={true}
                                    value={dataForm.OTTypeID}
                                    label={Config.lang("Loai_tach_ca")}
                                    onValueChanged={(e) => this.handleChange("OTTypeID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                    <FormGroup style={{marginBottom: 34}}>
                        <Attachments
                            ref={(ref) => (this.attRef = ref)}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={loading || uploading || isView}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{justifyContent: "space-between"}}>
                    <div>
                        <IconButton aria-label="view" disabled={loading || uploading || isView}
                                    onClick={this.onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    <div>
                        <ButtonGeneral
                            name={Config.lang("Luu")}
                            typeButton={"save"}
                            disabled={!allowSave || loading || isView || loadingCboOtType || loadingCboEmployees || disabled || uploading}
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onStore}
                        />
                    </div>
                </Modal.Actions>
            </>
        );
    }

    componentDidMount() {
        const {mode} = this.props;
        if (mode === "edit" || mode === "view") {
            this.loadForm();
            this.loadAttachments();
            this.loadCboOtType();
        }
        this.checkOTType();
        this.loadCboEmployees();
    }
}

export default compose(
    connect(
        (state) => ({
            getCboEmployees: state.general.getCboEmployees,
            getCboOtType: state.W29F2012.getCboOtType,
            getForm: state.W29F2012.getForm,
        }),
        (dispatch) => ({
            w29f2012Actions: bindActionCreators(W29F2012Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W29F2012);
