/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/29/2020
 * @Example
 */

import React from "react";
import {connect} from "react-redux";
import * as W29F2000Actions from "../../../../redux/W2X/W29F2000/W29F2000_actions";
import * as W09F6000Actions from "../../../../redux/W0X/W09F6000/W09F6000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap"
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Checkbox, Combo} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";
import ButtonCustom from "../../../common/button/button-custom";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import {Column} from "devextreme-react/data-grid";
import Filter from "../../../filter/filter";
import {Icon} from "@material-ui/core";
import _ from "lodash";
import HeadClick from "../../../grid-container/head-click";
import {TextBox} from 'devextreme-react/text-box';
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import {Typography, TreeView, Tooltip, Dropdown, TextInput} from "diginet-core-ui/components";

const styles = {
    boxShift: {
        padding: '10px 6px',
        display: 'block',
        width: '56px',
        textAlign: "center",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        marginRight: 5,
        backgroundColor: "#ddd"
    },
    iconButton: {
        width: 30,
        height: 30,
        position: "absolute",
        left: "1rem",
        top: 0
    },
    dataBox: {
        '&:before, &:after': {
            content: "none !important"
        },
        '& input': {
            textAlign: "center"
        }
    }
};

class W29F2000 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            selectedRowKeys: [],
            timeKeepingList: [],
            timeCurrent: moment(),
            IsCondition: 1, //0: thang, 1: tuan
            month: moment().format("MM/YYYY"),
            dateFrom: moment().startOf("week"),
            dateTo: moment().endOf("week"),
            Period: null,
            MinDatePeriod: null,
            MaxDatePeriod: null,
            ShiftID: "",
            dataColumns: [],
            dataGrid: [],
            dataCboShifts: [],
            loaded: false,
            dataLoading: false,
            dataOrgChart: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            dataCboDirectManager: {
                rows: [],
                total: 0,
            },
            Project: null,
            cboProjectLoading: false,
            cboDirectManagerLoading: false,
            filter: {
                OrgChartID: "",
                ProjectID: "",
                DirectManagers: [],
                EmployeeID: ""
            },
            isUpdated: false,
            filterGridValue: [],
            cboGroupEmployee: {},
            GroupID: ''
        };
        this.Period = {
            TranMonth: null,
            TranYear: null,
        };
        this.filterGrid = {};
        this.timer = null;
        this.selectedRange = {};
        this.tmpClipboard = {
            numCols: 0,
            numRows: 0,
            values: []
        };
        this.filterCboProjects = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        };
        this.filterCboDirectManager = {
            timer: null,
            skip: 0,
            limit: 50,
            strSearch: ""
        };
        this.dataGrid = null;
        this.isUpdated = false;
        this.timer = null;
    }

    async loadPermission() {
        await this.props.generalActions.getPermission("W29F2000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboProjects = (isReset) => {
        const params = {
            HostID: "",
            FormID: "W29F2000",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({cboProjectLoading: true});
        this.props.generalActions.getCboProjects(params, (errors, data) => {
            this.setState({cboProjectLoading: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboProjects} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadWorkingDayType = () => {
        this.props.w29f2000Actions.getWorkingDayType((errors) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
        });
    };

    loadCboPeriod = () => {
        const params = {
            DivisionID: Config.getDivisionID()
        };
        this.props.generalActions.getCboPeriod(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const Period = data.find(d => Number(d.TranMonth) === Number(this.Period.TranMonth) && Number(d.TranYear) === Number(this.Period.TranYear));
                this.setState({Period: Period});
            }
        });
    };

    loadCboShifts = () => {
        const {OrgChartID, ProjectID} = this.state.filter;
        const params = {
            DivisionID: Config.getDivisionID(),
            OrgChartID: _.get(OrgChartID, "current", ""),
            WorkDayType: "",
            AfterOTHours: 0,
            Language: Config.language || "84",
            ProjectID: _.get(ProjectID, "current", ""),
        };
        this.props.w29f2000Actions.getCboShift(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataCboShifts: data
                }, () => {
                    if (this.dataGrid) this.dataGrid.instance.repaint();
                })
            }
        });
    };

    loadDateByTrans = (TranMonth, TranYear) => {
        const _TranMonth = TranMonth || Config.profile.TranMonth || moment().format("M");
        const _TranYear = TranYear || Config.profile.TranYear || moment().format("YYYY");
        const params = {
            TranMonth: _TranMonth,
            TranYear: _TranYear
        };
        this.props.generalActions.getDateByTrans(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {IsCondition} = this.state;
                const dateTo = IsCondition === 1 ? moment(data.MinDate).endOf("week") : moment(data.MinDate).endOf("month");
                this.Period = {
                    TranMonth: _TranMonth,
                    TranYear: _TranYear
                };
                this.setState({
                    month: _TranMonth + "/" + _TranMonth,
                    dateFrom: moment(data.MinDate) || moment().startOf("week"),
                    dateTo: dateTo,
                    MinDate: moment(data.MinDate) || null,
                    MaxDate: moment(data.MaxDate) || null,
                }, () => {
                    this.loadDataGrid();
                });
            }
        });
    };

    loadDataOrgChart = () => {
        this.props.generalActions.getOrgCharts({}, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const dataSource = this._generateDataOrg(data, "OrgChartID", "OrgChartParentID");
                this.setState({dataOrgChart: dataSource});
            }
        });
    };

    _generateDataOrg = (dataSource, valueExpr, parentIdExpr) => {
        return dataSource.map((e) => {
            if (e[valueExpr] && e[parentIdExpr] && e[valueExpr] === e[parentIdExpr]) {
                delete e[parentIdExpr];
                e.expanded = true;
            } else if (!e[parentIdExpr]) {
                e.expanded = true;
            }
            return e;
        });
    };

    loadCboDirectManager = (isReset = false) => {
        const {dataCboDirectManager} = this.state;
        const params = {
            Type: "DirectManagerID",
            FormID: "W29F2000",
            Language: Config.language || "84",
            Mode: 0,
            skip: this.filterCboDirectManager.skip,
            limit: this.filterCboDirectManager.limit,
            SearchValue: this.filterCboDirectManager.strSearch,
            SearchValue2: "",
            SearchValueUnicodeCombine: "",
            TranMonth: 0,
            TranYear: 0,
        }
        this.setState({cboDirectManagerLoading: true});
        this.props.w09f6000Actions.getCboDirectManager(params, (err, data) => {
            this.setState({cboDirectManagerLoading: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const dataRows = _.get(data, "rows", data) ?? [];
                const totalRows = _.get(data, "total", _.size(data)) ?? 0
                this.setState({
                    dataCboDirectManager: {
                        rows: isReset ? dataRows : _.concat(dataCboDirectManager.rows, dataRows),
                        total: totalRows
                    }
                })
            }
        })
    };

    loadDataGrid = () => {
        if (this.isUpdated) {
            Config.popup.show("YES_NO", Config.lang("Co_du_lieu_da_duoc_thay_doi_Ban_co_muon_tiep_tuc_khong"), () => {
                this._getDataGrid();
            });
        } else {
            this._getDataGrid();
        }
    };

    _getDataGrid = () => {
        const {dateFrom, dateTo, GroupID, filter} = this.state;
        const {OrgChartID, ProjectID, EmployeeID , DirectManagers} = filter;
        const params = {
            DivisionID: Config.getDivisionID(),
            AttendanceDateFrom: dateFrom.format("YYYY-MM-DD"),
            AttendanceDateTo: dateTo.format("YYYY-MM-DD"),
            OrgChartID,
            ProjectID,
            EmployeeID,
            DirectManagerID: JSON.stringify(DirectManagers),
            Language: Config.language || "84",
            GroupID: GroupID ?? '',
        };
        this.setState({dataLoading: true});
        this.props.w29f2000Actions.getDataGrid(params, (errors, data) => {
            this.setState({dataLoading: false});
            this.setState({isUpdated: false});
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                if (data.column) {
                    const columns = this.renderGridColumns(data.column, false);
                    this.setState({dataColumns: columns}, () => {
                        if (data.row) {
                            this.setState({dataGrid: data.row});
                        }
                    });
                }
            }
        });
    };

    loadCboGroupEmployee = () => {
        const params = {
            FormID: "W29F2000",
            Language: Config.language || 84,
            Mode: 0,
            skip: 0,
            limit: 50,
            Type: 'Group',
            search: "W29F2000",
            search2: ""
        };
        this.props.w29f2000Actions.getCboGroupEmployee(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                this.setState({cboGroupEmployee: data})
            }
        });
    };


    UNSAFE_componentWillMount = () => {
        this.loadDateByTrans();
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.iPermission <= 0) return;
        this.loadCboProjects();
        this.loadDataOrgChart()
        this.loadWorkingDayType();
        this.loadCboShifts();
        this.loadCboPeriod();
        this.loadCboGroupEmployee();
        this.loadCboDirectManager();
    };

    componentWillUnmount = () => {
        this.setState({
            selectedRowKeys: [],
            timeKeepingList: [],
            timeCurrent: null,
            month: null,
            dateFrom: null,
            dateTo: null,
            Period: null,
            MinDatePeriod: null,
            MaxDatePeriod: null,
            ShiftID: "",
            dataColumns: [],
            dataGrid: [],
            dataCboProjects: {rows: [], total: 0},
            Project: null,
            filterGridValue: [],
        });
        this.filterGrid = null;
        this.timer = null;
        this.selectedRange = null;
        this.tmpClipboard = null;
        this.filterCboProjects = null;
        this.dataGrid = null;
        this.timer = null;
    };

    renderDefaultColumns = () => {
        return [
            {
                Caption: Config.lang("Ma_nhan_vien"),
                AllowEditing: false,
                AllowFixing: !Config.isMobile,
                AllowCopying: false,
                AllowPasting: false,
                Fixed: !Config.isMobile,
                DataType: "string",
                FieldName: "EmployeeID",
                Width: 150,
                Alignment: "left",
                CellRender: (e) => this.renderEmployeeID(e)
            },
            {
                Caption: Config.lang("Ten_nhan_vien"),
                AllowEditing: false,
                AllowFixing: !Config.isMobile,
                AllowCopying: false,
                AllowPasting: false,
                Fixed: !Config.isMobile,
                DataType: "string",
                FieldName: "EmployeeName",
                MinWidth: 210,
                Alignment: "left",
                CellRender: (e) => this.renderEmployeeName(e)
            },
            {
                Caption: Config.lang("Du_an"),
                AllowEditing: false,
                AllowFixing: !Config.isMobile,
                AllowCopying: false,
                AllowPasting: false,
                Fixed: !Config.isMobile,
                DataType: "string",
                FieldName: "ProjectName",
                Width: 200,
                Alignment: "left"
            },
        ];
    };

    renderGridColumns = (columns, returnArray = true) => {
        if (!columns || (columns && columns.length < 1)) return false;
        columns = _.orderBy(columns, "Orders");//columns.sort((a, b) => a.Orders > b.Orders);
        columns = columns.map(col => ({...col, AllowEditing: true, AllowCopying: true, AllowPasting: true}));
        const defaultColumns = this.renderDefaultColumns();
        columns = defaultColumns.concat(columns);
        let _columns = [];
        if (returnArray) {
            columns.forEach(col => {
                const _col = {
                    caption: col.Caption,
                    allowEditing: col.AllowEditing,
                    allowFixing: col.AllowFixing,
                    allowCopying: col.AllowCopying,
                    allowPasting: col.AllowPasting,
                    fixed: col.Fixed,
                    dataType: this.convertDataType(col.DataType),
                    dataField: col.FieldName,
                    format: col.Format,
                    width: col.Width,
                    maxWidth: col.MaxWidth,
                    minWidth: col.MinWidth,
                    visible: col.Visible,
                    alignment: col.Alignment ? col.Alignment : "center",
                    headerCellRender: (e) => this.renderHeaderColumns(e, col),
                    cellRender: (e) => this.cellRender(e)

                };
                _columns.push(_col);
            });
        } else {
            _columns = columns.map((col, indx) => {
                return (
                    <Column
                        key={indx}
                        fixed={col.Fixed}
                        caption={col.Caption}
                        allowEditing={col.AllowEditing}
                        allowFixing={col.AllowFixing}
                        allowCopying={col.AllowCopying}
                        allowPasting={col.AllowPasting}
                        allowSorting={false}
                        width={col.Width}
                        dataField={col.FieldName}
                        dataType={this.convertDataType(col.DataType)}
                        format={col.Format}
                        maxWidth={col.MaxWidth}
                        minWidth={col.MinWidth}
                        visible={col.Visible}
                        alignment={col.Alignment ? col.Alignment : "center"}
                        headerCellRender={(e) => this.renderHeaderColumns(e, col)}
                        cellRender={(e) => this.cellRender(e, col)}
                    />
                );
            });
        }
        return _columns;
    };

    renderHeaderColumns = (e, col) => {
        if (!e || !col) return false;
        // return <div style={{color: col.HeaderTextColor}}>{col.Caption}</div>
        return <HeadClick selectedRange={this.selectedRange}
                          dataHeaderCell={e}
                          allowClick={true}
                          color={col.HeaderTextColor}
                          callbackAfterCopy={e => {
                              if (!this.state.isUpdated) this.setState({isUpdated: e.IsUpdated})
                          }}
        />
    };

    cellRender = (e, col) => {
        const {dataCboShifts} = this.state;
        const {data, column, rowIndex} = e;
        if (column && column.dataField.indexOf("ShiftID") > -1) {
            const cellElement = e.component.getCellElement(rowIndex, column.dataField);
            if (data && cellElement) {
                const shift = dataCboShifts.find(s => {
                    return s.ShiftID === data[column.dataField]
                });
                let bgColor = "";
                if (shift && shift.ShiftColor) {
                    bgColor = shift.ShiftColor.indexOf("#") > -1 ? shift.ShiftColor : "#" + shift.ShiftColor;
                }
                cellElement.style.backgroundColor = bgColor;
            }
        }
        if (col && col.CellRender) {
            return col.CellRender(e);
        } else {
            const shift = dataCboShifts.find(s => {
                return s.ShiftID === e.value;
            });
            if (shift) {
                const renderTooltip = () => {
                    return (
                        <div className="display_col align-left">
                            {shift.TimeStart && shift.TimeEnd &&
                            <div>{`${Config.lang("Gio_di_ca")}: ${shift.TimeStart} - ${shift.TimeEnd}`}</div>}
                            {shift.RestTime1Begin && shift.RestTime1End && (
                                <div>{`${Config.lang("Gio_nghi_giua_ca")}: ${shift.RestTime1Begin} - ${shift.RestTime1End}`}</div>
                            )}
                            {shift.PreOTBegin && shift.PreOTEnd &&
                            <div>{`${Config.lang("Tang_ca_truoc")}: ${shift.PreOTBegin} - ${shift.PreOTEnd}`}</div>}
                            {shift.OverTimeBegin && shift.OverTimeEnd && (
                                <div>{`${Config.lang("Tang_ca_sau")}: ${shift.OverTimeBegin} - ${shift.OverTimeEnd}`}</div>
                            )}
                        </div>
                    );
                };
                return (
                    <div className="display_row align-center valign-middle">
                        <Tooltip arrow forceDirection title={renderTooltip()}>
                            {e.value}
                        </Tooltip>
                    </div>
                );
            } else return e.value;
        }
    };

    renderEmployeeID = (e) => {
        const {data} = e.row;

        return (
            <div className={"display_row align-center flex-wrap"}>
                <UserImage data={data} width={42} height={42}/>
                {data.EmployeeID}
            </div>
        );
    };

    renderEmployeeName = (e) => {
        if (!e) return false;
        const {data} = e.row;
        return <UserName data={data}/>
    };

    convertDataType = (dataType) => {
        if (!dataType) return "string";
        switch (dataType) {
            case "VARCHAR":
                return "string";
            case "INT":
                return "integer";
            default:
                return dataType;
        }
    };

    setStateErrorText = (value) => {
        const {error} = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    onSave = () => {
        if (this.dataGrid) {
            Config.popup.show('YES_NO', Config.lang("Ban_co_chac_muon_luu_khong?"), () => {
                this._saveData();
            });
        } else {
            Config.popup.show('INFO', Config.lang("Loi_chua_xac_dinh"));
            return false;
        }
    };

    _saveData = () => {
        if (this.dataGrid) {
            this.dataGrid.instance.saveEditData();
            const dataSource = this.dataGrid.instance.option("dataSource");
            const dataUpdated = dataSource && dataSource.filter(data => data && data.IsUpdated === 1);
            if (dataUpdated && dataUpdated.length > 0) {
                const filter = this.filterLocal;
                const {dateFrom, dateTo} = this.state;
                const params = {
                    DivisionID: Config.getDivisionID(),
                    AttendanceDateFrom: dateFrom ? dateFrom.format("YYYY-MM-DD") : null,
                    AttendanceDateTo: dateTo ? dateTo.format("YYYY-MM-DD") : null,
                    Language: Config.language || "84",
                    OrgChartID: filter && filter.OrgChartID ? filter.OrgChartID : "",
                    ProjectID: filter && filter.Project && filter.Project.ProjectID ? filter.Project.ProjectID : "",
                    data: JSON.stringify(dataUpdated)
                };
                this.setState({dataLoading: true});
                this.props.w29f2000Actions.saveData(params, (error, data) => {
                    this.setState({dataLoading: false});
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    }
                    if (data) {
                        if (data.Status === 0) {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            this.loadDataGrid();
                        } else {
                            Config.popup.show("INFO", data.Message);
                            return false;
                        }
                    }
                });
            }
        }
    };

    onFilter = () => {
        this.loadCboShifts();
        this.loadDataGrid();
    };

    handleFilterChange = (key, e) => {
        const {filter} = this.state;
        this.setState({filter: {...filter, [key]: _.get(e, "value", key === "DirectManagers" ? [] : "")}})
    };

    onLoadDataFilter = () => {

    };

    renderFilters = () => {
        const {classes} = this.props;
        const {
            loaded,
            dataCboProjects,
            cboProjectLoading,
            dataOrgChart,
            dataCboDirectManager,
            cboDirectManagerLoading,
            filter,
            dataLoading
        } = this.state;
        return (
            <Filter
                isUseDDCore={true}
                disabled={loaded || dataLoading}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    const {DirectManagers, OrgChartID, ProjectID, EmployeeID} = filter;
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Dropdown
                                            allowSearch
                                            multiple
                                            selectBox
                                            closeAfterSelect={false}
                                            clearAble
                                            dataSource={dataCboDirectManager.rows}
                                            total={dataCboDirectManager.total}
                                            skip={this.filterCboDirectManager.skip}
                                            limit={this.filterCboDirectManager.limit}
                                            displayExpr={'{DirectManagerID} - {DirectManagerName}'}
                                            keyExpr={'DirectManagerID'}
                                            valueExpr={'DirectManagerID'}
                                            value={DirectManagers}
                                            loading={cboDirectManagerLoading}
                                            viewType={'outlined'}
                                            label={Config.lang("Nguoi_quan_ly_truc_tiep")}
                                            placeholder={Config.lang('Chon')}
                                            onChange={(e) => this.handleFilterChange("DirectManagers", e)}
                                            onInput={(e) => {
                                                if (this.filterCboDirectManager.timer) clearTimeout(this.filterCboDirectManager.timer);
                                                this.filterCboDirectManager.strSearch = e.target.value;
                                                this.filterCboDirectManager.timer = setTimeout(() => {
                                                    this.filterCboDirectManager.skip = 0;
                                                    this.filterCboDirectManager.limit = 50;
                                                    this.loadCboDirectManager(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboDirectManager.skip = e.skip;
                                                this.filterCboDirectManager.limit = e.limit;
                                                this.loadCboDirectManager();
                                            }}
                                            style={{margin: 0}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            selectBox
                                            dataSource={dataOrgChart}
                                            valueExpr={"OrgChartID"}
                                            displayExpr={"OrgName"}
                                            label={Config.lang("Co_cau_to_chuc")}
                                            keyExpr={'OrgName'}
                                            onChange={(e) => this.handleFilterChange("OrgChartID", e)}
                                            value={OrgChartID}
                                            clearAble
                                            viewType={'outlined'}
                                            placeholder={Config.lang('Chon')}
                                            style={{margin: 0}}
                                        >
                                            <TreeView
                                                allowSearch
                                                dataSource={dataOrgChart}
                                                disabledRelevantValue
                                                displayExpr={"OrgName"}
                                                id={"OrgChartID"}
                                                multipleValueMode="single"
                                                onChange={(e) => this.handleFilterChange("OrgChartID", e)}
                                                parentID={"OrgChartParentID"}
                                                value={OrgChartID}
                                                valueExpr={"OrgChartID"}
                                            />
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            allowSearch
                                            dataSource={dataCboProjects.rows}
                                            total={dataCboProjects.total}
                                            skip={this.filterCboProjects.skip}
                                            limit={this.filterCboProjects.limit}
                                            displayExpr={"ProjectName"}
                                            keyExpr={'ProjectID'}
                                            valueExpr={'ProjectID'}
                                            value={ProjectID}
                                            loading={cboProjectLoading}
                                            viewType={'outlined'}
                                            clearAble
                                            label={Config.lang('Du_an')}
                                            placeholder={Config.lang('Chon')}
                                            onChange={(e) => this.handleFilterChange("ProjectID", e)}
                                            onInput={(e) => {
                                                if (this.filterCboProjects.timer) clearTimeout(this.filterCboProjects.timer);
                                                this.filterCboProjects.strSearch = e.target.value;
                                                this.filterCboProjects.timer = setTimeout(() => {
                                                    this.filterCboProjects.skip = 0;
                                                    this.filterCboProjects.limit = 20;
                                                    this.loadCboProjects(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboProjects.skip = e.skip;
                                                this.filterCboProjects.limit = e.limit;
                                                this.loadCboProjects();
                                            }}
                                            style={{margin: 0}}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <TextInput
                                            label={Config.lang("Ma_nhan_vien")}
                                            placeholder={Config.lang("Nhap")}
                                            type="text"
                                            viewType="outlined"
                                            value={EmployeeID}
                                            onChange={(e) => this.handleFilterChange("EmployeeID", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    className={classes.btnFilter}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    handleChange = (key, e) => {
        if (!key || !e) return false;
        switch (key) {
            case "Period":
                const Period = e.value;
                if (Period) {
                    this.loadDateByTrans(Period.TranMonth, Period.TranYear);
                }
                this.setState({
                    Period: Period
                });
                break;
            case "ShiftID":
                this.setState({ShiftID: e.value || ""});
                break;
            case "DateFrom":
                this.setState({dateFrom: moment(e.value)}, () => this.loadDataGrid());
                break;
            case "DateTo":
                this.setState({dateTo: moment(e.value)}, () => this.loadDataGrid());
                break;
            case "GroupID":
                this.setState({GroupID: e?.value ?? ''}, () => this.loadDataGrid());
                break;
            default:
                break;
        }
    };

    changeTypeTime = (e, value) => {
        const type = value ? "week" : "month";
        const {MinDate, MaxDate} = this.state;
        const {TranMonth, TranYear} = this.Period;
        switch (type) {
            case "week":
                this.setState({
                    IsCondition: 1, //week
                    month: null,
                    // timeCurrent: moment(MinDate),
                    dateFrom: MinDate.clone(),
                    dateTo: MinDate.clone().endOf("week")
                }, () => {
                    this.loadDataGrid();
                });
                break;
            case "month":
                this.setState({
                    IsCondition: 0, //month
                    // timeCurrent: moment(MinDate),
                    month: TranMonth + "/" + TranYear,
                    dateFrom: MinDate.clone(),
                    dateTo: MaxDate.clone()
                }, () => {
                    this.loadDataGrid();
                });
                break;
            default:
                break;
        }
    };

    changeTime = (type) => {
        if (!type) return;
        const {dateFrom, MinDate, MaxDate, IsCondition} = this.state;
        if (this.timer) clearTimeout(this.timer);
        switch (type) {
            case "next":
                if (IsCondition === 1) { //week
                    const _dateFrom = dateFrom.clone().add(1, 'week').startOf("week");
                    const _dateTo = _dateFrom.clone().endOf("week");
                    this.setState({
                        month: null,
                        dateFrom: _dateFrom,
                        dateTo: _dateTo > MaxDate ? MaxDate : _dateTo
                    }, () => {
                        this.timer = setTimeout(() => {
                            this.loadDataGrid();
                        }, 500);
                    });
                }
                // else { //month
                //     const now = dateFrom.add(1, 'month');
                //     this.setState({
                //         month: now.format("MM/YYYY"),
                //         dateFrom: now.startOf("month").clone(),
                //         dateTo: now.endOf("month").clone()
                //     }, () => {
                //         this.timer = setTimeout(() => {
                //             this.loadDataGrid();
                //         }, 500);
                //     });
                // }
                break;
            case "prev":
                if (IsCondition === 1) { //week
                    const _dateFrom = dateFrom.clone().subtract(1, 'week');
                    const _dateTo = _dateFrom.clone().endOf("week");
                    this.setState({
                        month: null,
                        dateFrom: _dateFrom < MinDate ? MinDate : _dateFrom,
                        dateTo: _dateTo
                    }, () => {
                        this.timer = setTimeout(() => {
                            this.loadDataGrid();
                        }, 500);
                    });
                }
                // else { //month
                //     const now = dateFrom.subtract(1, 'month');
                //     this.setState({
                //         month: now.format("MM/YYYY"),
                //         dateFrom: now.startOf("month").clone(),
                //         dateTo: now.endOf("month").clone()
                //     }, () => {
                //         this.timer = setTimeout(() => {
                //             this.loadDataGrid();
                //         }, 500);
                //     });
                // }
                break;
            default:
                break;
        }
    };

    onKeyDown = (e) => {
        const {event} = e;
        // const {rowIndx, colIndx, column} = this.postionFocused;
        const {startColumnIndex, startRowIndex, endColumnIndex, endRowIndex} = this.selectedRange;
        if (event && event.ctrlKey) {
            if (event.keyCode === 67) { //ctrl + C
                const columns = e.component.getVisibleColumns();
                let data = [];
                for (let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                    let dataRow = [];
                    for (let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                        const column = columns[colIndx];
                        if (column.allowCopying) {
                            dataRow.push(e.component.cellValue(rowIndx, colIndx));
                        }
                    }
                    data.push(dataRow)
                }
                this.tmpClipboard = {
                    numCols: endColumnIndex - startColumnIndex,
                    numRows: endRowIndex - startRowIndex,
                    values: data
                };
            } else if (event.keyCode === 86) { //ctrl + v
                if (this.tmpClipboard && this.tmpClipboard.values.length > 0) {
                    const colMaxTmp = endColumnIndex - startColumnIndex;
                    const rowMaxTmp = endRowIndex - startRowIndex;
                    const colIndxTmp = colMaxTmp > this.tmpClipboard.numCols ? colMaxTmp : this.tmpClipboard.numCols;
                    const rowIndxTmp = rowMaxTmp > this.tmpClipboard.numRows ? rowMaxTmp : this.tmpClipboard.numRows;
                    const values = this.tmpClipboard.values;
                    const rows = e.component.getVisibleRows();
                    const columns = e.component.getVisibleColumns();

                    let iRow = 0, iCol = 0;
                    for (let rowIndx = startRowIndex; rowIndx <= (startRowIndex + rowIndxTmp); rowIndx++) {
                        iCol = 0;
                        const rowData = rows[rowIndx] && rows[rowIndx].data ? rows[rowIndx].data : null;
                        for (let colIndx = startColumnIndex; colIndx <= (startColumnIndex + colIndxTmp); colIndx++) {
                            const column = columns[colIndx];
                            const dataField = column && column.dataField;
                            let workingDay = this.getWorkingDay(dataField);
                            if (rowData && rowData["IsProcess_" + workingDay] === 0 && column.allowPasting) {
                                e.component.cellValue(rowIndx, colIndx, values[iRow][iCol]);
                            }
                            iCol = iCol < this.tmpClipboard.numCols ? iCol + 1 : 0;
                        }
                        iRow = iRow < this.tmpClipboard.numRows ? iRow + 1 : 0;
                    }
                    e.component.saveEditData();
                }
            }
        } else if (event && event.keyCode === 46) {
            for (let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                for (let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                    e.component.cellValue(rowIndx, colIndx, "");
                    e.component.saveEditData();
                }
            }
        }
    };

    getWorkingDay = (field) => {
        if (!field) return "";
        const lField = field.split("_");
        if (lField && lField.length > 1) {
            return lField[1];
        }
        return "";
    };

    setShift = () => {
        const {ShiftID} = this.state;
        if (this.dataGrid && this.selectedRange && ShiftID) {
            let isUpdated = false;
            const {startColumnIndex, startRowIndex, endColumnIndex, endRowIndex} = this.selectedRange;
            for (let rowIndx = startRowIndex; rowIndx <= endRowIndex; rowIndx++) {
                for (let colIndx = startColumnIndex; colIndx <= endColumnIndex; colIndx++) {
                    this.dataGrid.instance.cellValue(rowIndx, colIndx, ShiftID);
                    isUpdated = true;
                }
            }
            if (isUpdated && !this.state.isUpdated) this.setState({isUpdated: true});
            this.dataGrid.instance.saveEditData();
            this.dataGrid.instance.refresh();
        }
    };

    getFilterGrid = () => {
        const filter = this.filterGrid;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    onEditingStart = (e) => {
        e.cancel = true;
    };

    getShiftColor = (shift) => {
        if (shift && shift.ShiftColor) {
            return shift.ShiftColor.indexOf("#") > -1 ? shift.ShiftColor : "#" + shift.ShiftColor;
        }
        return "";
    };

    fieldRender = (e) => {
        if (e && e.ShiftID) {
            const {classes} = this.props;
            let name = e ? e.TimeStart + " - " + e.TimeEnd : "";
            return (
                <div className={"display_row align-center"}>
               <span className={classes.boxShift}
                     style={{backgroundColor: this.getShiftColor(e)}}>{e.ShiftID || ""}</span>
                    <TextBox
                        className={classes.textBox}
                        defaultValue={name || ""}
                        readOnly={true}
                    />
                </div>
            )
        } else {
            return <TextBox defaultValue={""}
                            readOnly={true}
            />
        }
    };

    onExportExcel = () => {
        if (this.dataGrid) {
            this.dataGrid.instance.exportToExcel();
        }
    };

    render() {
        const {classes, getCboPeriod} = this.props;
        const {
            iPermission, dateFrom, dateTo, selectedRowKeys, IsCondition, ShiftID,
            dataColumns, dataGrid, dataLoading, Period, MinDate, MaxDate, isUpdated,
            filterGridValue, dataCboShifts, cboGroupEmployee
        } = this.state;
        if (!iPermission) return null;
        return (
            <FormGroup>
                <ActionToolbar title={Config.lang("Lap_lich_di_ca")}>
                    <div className={"display_row align-center align-between flex-wrap"} style={{width: '100%'}}>
                        <div className={"display_row align-center"}>
                            <ButtonGeneral
                                name={Config.lang("Luu")}
                                typeButton={"save"}
                                disabled={dataLoading || !isUpdated || !(iPermission > 1)}
                                style={{textTransform: "uppercase"}}
                                size={"large"}
                                className={"mgr5"}
                                onClick={this.onSave}
                            />
                            <ButtonGeneral
                                name={Config.lang("Xuat_Excel")}
                                typeButton={"excel"}
                                color={"primary"}
                                variant={"outlined"}
                                disabled={dataLoading || isUpdated}
                                style={{textTransform: "uppercase"}}
                                size={"large"}
                                className={"mgr5"}
                                onClick={this.onExportExcel}
                            />
                            <Combo
                                margin={"dense"}
                                style={{margin: 0}}
                                dataSource={cboGroupEmployee.rows}
                                displayExpr={"GroupName"}
                                valueExpr={"GroupID"}
                                stylingMode={"outlined"}
                                showClearButton={true}
                                disabled={dataLoading}
                                label={Config.lang('Chon_nhom_nhan_vien')}
                                onValueChanged={e => this.handleChange("GroupID", e)}
                            />
                        </div>
                        <div className={"display_row align-center valign-middle flex-wrap"}>
                            <div className={"display_row align-center mgr10"}>
                                {/*<Label className={"mgr10"}>{Config.lang("Ngay")}</Label>*/}
                                <IconButton aria-label="prev" className={"mgr5"} size="medium" style={{padding: 5}}
                                            disabled={dataLoading || dateFrom <= MinDate}
                                            onClick={() => this.changeTime("prev")}>
                                    <Icon fontSize={'small'} className="fas fa-angle-double-left"/>
                                </IconButton>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    width={100}
                                    hideIcon={true}
                                    dateBoxProps={{
                                        className: classes.dataBox,
                                        showDropDownButton: false
                                    }}
                                    disabled={dataLoading}
                                    value={dateFrom}
                                    min={MinDate}
                                    max={dateTo}
                                    styleForm={{margin: 0}}
                                    stylingMode={"underlined"}
                                    onValueChanged={e => this.handleChange("DateFrom", e)}
                                />
                                -
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    width={100}
                                    hideIcon={true}
                                    dateBoxProps={{
                                        className: classes.dataBox,
                                        showDropDownButton: false
                                    }}
                                    min={dateFrom}
                                    max={MaxDate}
                                    disabled={dataLoading}
                                    className={"mgr10"}
                                    value={dateTo}
                                    styleForm={{margin: 0}}
                                    stylingMode={"underlined"}
                                    onValueChanged={e => this.handleChange("DateTo", e)}
                                />
                                <IconButton aria-label="next" className={"mgl5"} size="medium" style={{padding: 5}}
                                            disabled={dataLoading || dateTo >= MaxDate}
                                            onClick={() => this.changeTime("next")}>
                                    <Icon fontSize={'small'} className="fas fa-angle-double-right"/>
                                </IconButton>
                            </div>
                            <Combo
                                placeholder={Config.lang("Ky")}
                                dataSource={getCboPeriod}
                                displayExpr={"PeriodTime"}
                                // valueExpr={"TempCol"}
                                stylingMode={'underlined'}
                                disabled={dataLoading}
                                style={{width: "auto", margin: 0}}
                                onValueChanged={e => this.handleChange("Period", e)}
                                selectProps={{
                                    dropDownOptions: {
                                        minWidth: 260
                                    }
                                }}
                                value={Period}
                            />
                        </div>
                    </div>
                </ActionToolbar>
                <div className={"hidden"}>{this.state && this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar className={"mgt0"} alignment={"flex-end"} allwaysTop={false}>
                        <div className={"display_row align-center align-between flex-wrap"} style={{width: '100%'}}>
                            <Checkbox
                                checked={IsCondition === 1}
                                disabled={dataLoading}
                                label={Config.lang("Hien_thi_theo_tuan")}
                                color={'primary'}
                                onChange={this.changeTypeTime}
                            />
                            <div className={"display_row align-center valign-middle"}>
                                <ButtonCustom
                                    name={Config.lang("Gan_ca")}
                                    color={"primary"}
                                    variant={"contained"}
                                    size={"medium"}
                                    className={"mgr10"}
                                    disabled={dataLoading || !ShiftID}
                                    onClick={this.setShift}
                                />
                                <Combo
                                    placeholder={Config.lang("Ca")}
                                    dataSource={dataCboShifts}
                                    displayExpr={"ShiftName"}
                                    valueExpr={"ShiftID"}
                                    stylingMode={'underlined'}
                                    style={{width: "auto", margin: 0}}
                                    onValueChanged={e => this.handleChange("ShiftID", e)}
                                    fieldRender={this.fieldRender}
                                    itemRender={(e) => {
                                        if (!e.ShiftID) return null;
                                        return (
                                            <div className={"display_row align-center valign-top"}
                                                 title={e.ShiftID + " - " + e.ShiftName}>
                                                <div className={classes.boxShift}
                                                     style={{backgroundColor: this.getShiftColor(e)}}>
                                                    {e.ShiftID}
                                                </div>
                                                <div className="display_col">
                                                    <div>{" " + e.TimeStart + " - " + e.TimeEnd}</div>
                                                    {(e.RestTime1Begin || e.RestTime1End) && (
                                                        <div
                                                            style={{
                                                                backgroundColor: Config.color ? Config.color["SubColor"] : "#e4e5f2",
                                                                padding: "0 16px"
                                                            }}
                                                        >
                                                            <Typography
                                                                type="p3">{" " + e.RestTime1Begin + " - " + e.RestTime1End}</Typography>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    selectProps={{
                                        dropDownOptions: {
                                            minWidth: 260
                                        }
                                    }}
                                    value={ShiftID}
                                />
                            </div>
                        </div>
                    </ActionToolbar>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                reference={ref => this.dataGrid = ref}
                                loading={dataLoading}
                                listPerPage={[15, 30, 50, 100]}
                                selectedRowKey={selectedRowKeys}
                                style={{border: "none", userSelect: "none"}}
                                dataSource={dataGrid}
                                height={Config.getHeightGrid() - 50}
                                showBorders={false}
                                typePaging={"normal"}
                                keyExpr={"EmployeeID"}
                                totalItems={dataGrid && dataGrid.length}
                                showColumnLines={false}
                                showRowLines={false}
                                hoverStateEnabled={true}
                                filterRow={{
                                    visible: true,
                                    showOperationChooser: false,
                                }}
                                filterPanel={{
                                    visible: true
                                }}
                                filterBuilderPopup={{
                                    onInitialized: (e) => {
                                        e.component.dispose();
                                        e.element.remove();
                                    }
                                }}
                                filterValue={filterGridValue}
                                editing={{
                                    mode: 'cell',
                                    refreshMode: 'reshape',
                                    // useIcons: true,
                                    allowUpdating: true,
                                    startEditAction: "dblClick"
                                }}
                                scrolling={{
                                    scrollByContent: false,
                                    scrollByThumb: true,
                                }}
                                onFilterGrid={(e) => {
                                    const {column, filterValue} = e;
                                    if (column) {
                                        if (column.dataField === "EmployeeID") {
                                            let dataFilter = [];
                                            if (filterValue) {
                                                if (filterValue.indexOf(" ") > -1 || filterValue.indexOf(";") > -1) {
                                                    const arrFilters = filterValue.indexOf(";") > -1 ? filterValue.split(";") : filterValue.split(" ");
                                                    arrFilters.forEach(f => {
                                                        dataFilter.push(["EmployeeID", "contains", f]);
                                                        dataFilter.push("or");
                                                    });
                                                    dataFilter.pop();
                                                } else {
                                                    dataFilter = ["EmployeeID", "contains", filterValue];
                                                }
                                            }
                                            this.filterGrid.EmployeeID = dataFilter;
                                            this.setState({filterGridValue: this.getFilterGrid()});

                                        } else {
                                            this.filterGrid[column.dataField] = filterValue ? [column.dataField, "contains", filterValue] : [];
                                            this.setState({filterGridValue: this.getFilterGrid()});
                                        }
                                    } else {
                                        this.filterGrid = {};
                                        this.setState({filterGridValue: []});
                                    }
                                }}
                                onEditingStart={this.onEditingStart}
                                onKeyDown={this.onKeyDown}
                                onCellSelectionChanged={(e) => {
                                    if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                        this.selectedRange = e.selectedRange;
                                    }
                                }}
                                onRowUpdating={(e) => {
                                    const {newData} = e;
                                    const dataFields = Object.keys(newData);
                                    dataFields.forEach(field => {
                                        if (field) {
                                            const _fields = field.split("_");
                                            if (_fields && _fields.length > 1 && _fields[0] === "ShiftID" && _fields[1]) {
                                                const workingDay = _fields[1];
                                                if (e.oldData["IsUpdate_" + workingDay] === 0) {
                                                    e.newData["IsUpdate_" + workingDay] = 1;
                                                }
                                            }
                                        }
                                    });
                                    // if (!this.state.isUpdate) this.setState({isUpdate: true});
                                }}
                                onRowUpdated={(e) => {
                                    e.data.IsUpdated = 1;
                                    if (!this.state.isUpdated) this.setState({isUpdated: true});
                                }}
                                gridProps={{
                                    onExporting: (e) => {
                                        e.fileName = "Lap_lich_di_ca_" + new Date().getTime();
                                    }
                                }}
                            >
                                {dataColumns && dataColumns}
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        );
    }
}

export default compose(connect((state) => ({
    getColumns: state.W29F2000.getColumns,
    getCboColumns: state.W29F2000.getCboColumns,
    getCboShifts: state.W29F2000.getCboShifts,
    getWorkingDayType: state.W29F2000.getWorkingDayType,
    getMaster: state.W29F2000.getMaster,
    getGridMaster: state.W29F2000.getGridMaster,
    getCboPeriod: state.general.getCboPeriod
}), (dispatch) => ({
    w09f6000Actions: bindActionCreators(W09F6000Actions, dispatch),
    w29f2000Actions: bindActionCreators(W29F2000Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W29F2000);
