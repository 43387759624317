/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/30/2020
 * @Example
 */
import React from "react";
import {bindActionCreators, compose} from "redux";
import {withStyles, FormControl, Input, InputLabel} from "@material-ui/core";
import PropTypes from 'prop-types';
import ButtonGeneral from "../../../common/button/button-general";
import Config from "../../../../config";
import {connect} from "react-redux";
import * as w29f2011Actions from "../../../../redux/W2X/W29F2011/W29F2011_actions";
import {MForm} from "../../../common/form-material";
import FormHelperText from "@material-ui/core/FormHelperText";
import PopoverAction from "../../../grid-container/popover-action";
import _ from "lodash"

const styles = theme => {
    return {
        card: {
            width: "590px",
            height: "178px",
            padding: "37px 28px 0px 28px",
            [theme.breakpoints.down('xs')]: {
                width: "300px",
                height: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    }
};


class W29F2011Popover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            errorNote: ""
        };
    }

    onSave = async (numberStatus) => {
        const {data, funcLoadGrid} = this.props;
        const {notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (numberStatus === 100) {
            if (validate && validate.errorNote) {
                this.setState(validate);
                return false;
            }
        }
        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || '84',
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: notes,
            Mode: 0
        };
        await this.props.w29f2011Actions.approvalStatus(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;

            }
            if (data) {
                if (data.Status === 1) {
                    const msg = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', msg);
                    return false;
                } else {
                    const msg = numberStatus === 1 ? Config.lang("DHR_Duyet_thanh_cong") : Config.lang("DHR_Tu_choi_thanh_cong");
                    Config.notify.show("success", msg, 3000);
                    funcLoadGrid && funcLoadGrid();
                    this.setState({
                        notes: "", errorNote: ""
                    });
                }
            }
        });

        if (this.popover) this.popover.instance.hide();
    };

    onCancel = (numberStatus) => {
        const {data, funcLoadGrid} = this.props;
        const {notes} = this.state;
        const validate = MForm.isRequired(notes, "errorNote");
        if (validate && validate.errorNote) {
            this.setState(validate);
            return false;
        }
        const params = {
            TransID: data.TransID,
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || '84',
            EmployeeID: data.EmployeeID,
            ApprovalLevel: data.ApprovalLevel,
            ApprovalFlowID: data.ApprovalFlowID,
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: notes
        };
        Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_chan_bo_duyet"), () => {
            this.props.w29f2011Actions.cancelApproval(params, error => {
                if (error) {
                    Config.popup.show("ERROR", _.get(error, "Message", Config.lang("DHR_Loi_chua_xac_dinh", "")));
                    return false;
                } else {
                    Config.notify.show("success", Config.lang("DHR_Bo_duyet_thanh_cong"), 3000);
                    funcLoadGrid && funcLoadGrid();
                    this.setState({
                        notes: "", errorNote: ""
                    });
                }
            });
        });
        if (this.popover) this.popover.instance.hide();
    };

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    onCheck = () => {
        const {anchorEl} = this.props;
        if (this.popover) this.popover.instance.show(anchorEl);
    };

    onClose = () => {
        if (this.popover) this.popover.instance.hide();
    };

    render() {
        const {type, classes, data} = this.props;
        const {notes, errorNote} = this.state;
        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    onHiding={() => this.setState({notes: "", errorNote: ""})}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes}
                                     style={{padding: "0px 0px 21px 0px"}}>
                            <InputLabel
                                color={type === "check" ? "primary" : "secondary"}
                                shrink={true}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                }}
                                classes={{shrink: classes.shrink}}
                            >
                                {Config.lang("DHR_Ghi_chu_duyet")}
                            </InputLabel>
                            <Input fullWidth={true} classes={{underline: classes.underline}}
                                   color={type === "check" ? "primary" : "secondary"}
                                   multiline={true}
                                   rowsMax={3}
                                   value={notes}
                                   onChange={this.onNoteChange}
                            >
                            </Input>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                        {
                            data ?
                                <>
                                    {Number(data.AppStatusID) !== 0 && <ButtonGeneral
                                        name={Config.lang("DHR_Bo_duyet")}
                                        color={"primary"}
                                        variant={"text"}
                                        className="pull-right"
                                        style={{textTransform: 'uppercase'}}
                                        size={"large"}
                                        onClick={() => this.onCancel(0)}
                                    />}
                                    {type === "check"
                                        ?
                                        <ButtonGeneral
                                            name={Config.lang("DHR_Duyet")}
                                            color={"primary"}
                                            variant={"text"}
                                            className="pull-right"
                                            style={{textTransform: 'uppercase'}}
                                            size={"large"}
                                            onClick={() => this.onSave(1)}
                                        />
                                        : type !== "cancel" ?
                                            <ButtonGeneral
                                                name={Config.lang("DHR_Tu_choi_W75")}
                                                color={"secondary"}
                                                variant={"text"}
                                                className="pull-right"
                                                style={{textTransform: 'uppercase'}}
                                                size={"large"}
                                                onClick={() => this.onSave(100)}
                                            />
                                            : ""
                                    }
                                </>
                                : ""
                        }
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }

}

W29F2011Popover.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
};

export default compose(connect((state) => ({
        approvalStatus: state.W29F2011.approvalStatus
    }),
    (dispatch) => ({
        w29f2011Actions: bindActionCreators(w29f2011Actions, dispatch),
    }), null, {forwardRef: true}
), withStyles(styles, {withTheme: true}))(W29F2011Popover);