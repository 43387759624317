/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 10/7/2020
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as W25F6000Actions from "../../../../redux/W2X/W25F6000/W25F6000_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap"
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Combo, TextField} from "../../../common/form-material";
import ActionToolbar                from "../../../common/toolbar/action-toolbar";
import {Column}                     from "devextreme-react/data-grid";
import GridContainer                from "../../../grid-container/grid-container";
import DateBoxPicker                from "../../../common/form-material/date-box";
import Modal                        from "../../../common/modal/modal";
import Divider                      from "@material-ui/core/Divider";
import NumberFormat                 from "react-number-format";
import {Checkbox, FormControlLabel,Typography, Tooltip} from "@material-ui/core";

const styles = (theme) => ({
    panel: {
        transition: 'all .4s'
    },
    panelForm: {
        transition: 'all .5s',
        opacity: 1
    },
    hiddenOpacity: {
        opacity: 0
    },
    leftPanelMinimum: {
        width: '74px',
    },
    rightPanelMinimum: {
        width: 'calc(100% - 74px)'
    },
    titleColorFilter: {
        backgroundColor: theme.palette.primary.main + ' !important',
        '& svg': {
            '& path': {
                fill: theme.palette.primary.contrastText
            }
        }
    },
    textError : {
        '& .MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100px',
        }
    },
    toolTipTitle: {
        '& .MuiTooltip-popper': {
            display: 'none'
        }
    }
});
class W25F6000 extends React.PureComponent {
    constructor(props) {
        super(props);
        const filters = this.getDataFilter(props.dataDefaults);
        this.state = {
            isSaving            : false,
            NotInRec            : false,
            NotInInterview      : false,
            dataDepartments     : [],
            dataTeam            : [],
            dataCboProjects     : {
                rows : [],
                total: 0
            },
            dataLaborObject     : [],
            dataGrid            : [],
            totalDataGrid       : 0,
            selectedRowKeys    : [],
            ReceivedDateFrom   : null,
            ReceivedDateTo     : null,
            AgeFrom            : 0,
            AgeTo              : 0,
            ExperienceYearsFrom: 0,
            ExperienceYearsTo  : 0,
            DesiredSalaryFrom  : 0,
            DesiredSalaryTo    : 0,
            error: {}
        };
        this.isSelecting = false;
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
        this.selectAll = false;
        this.changePage = false;
        this.filter = {
            limit: 15,
            skip: 0,
            ...filters
        };
        this.tmpRemoveSelectedRowKeys = [];
        this.allowSetDataDefault = false;
        this.chooseOnSelect = false;
        this.genders = [
            {
                SexID: 0,
                SexName: Config.lang("DHR_NamU")
            },
            {
                SexID: 1,
                SexName: Config.lang("DHR_Nu")
            }
        ];
    }

    loadCboRecruitmentChanel = () => {
        this.setLoading('CboRecruitmentChanel', true);
        this.props.w25F6000Actions.getCboRecruitmentChanel((errors) => {
            this.setLoading('CboRecruitmentChanel', false);
            if (errors) {
                let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('ERROR', message);
                return false;
            }
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({['loading' + key]: status});
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.dataDefaults) !== JSON.stringify(this.props.dataDefaults)) {
            const dataDefaults = this.props.dataDefaults;
            this.filter = this.getDataFilter(dataDefaults);
            if (dataDefaults && dataDefaults.selectedRowKeys) {
                this.tmpSelectedRowKeys = [];
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults && dataDefaults.isLoaded) {
                this.loadGrid(true);
            }
        }
    }


    componentDidMount() {
        this.loadCboRecruitmentChanel();
        this.loadGrid(true);
        const {dataDefaults} = this.props;
        if (dataDefaults) {
            if (dataDefaults.selectedRowKeys && dataDefaults.selectedRowKeys.length > 0) {
                dataDefaults.selectedRowKeys.forEach(select => {
                    if (this.tmpSelectedRowKeys.indexOf(select) < 0) {
                        this.tmpSelectedRowKeys.push(select);
                    }
                });
            }
            if (dataDefaults.isLoaded) {
                this.loadGrid(true);
            }
        }
    }

    getDataFilter = (dataDefaults) => {
        return {
            ...this.filter,
            ReceivedDateFrom: dataDefaults?.ReceivedDateFrom || null,
            ReceivedDateTo: dataDefaults?.ReceivedDateTo || null,
            AgeFrom: dataDefaults?.AgeFrom || 0,
            AgeTo: dataDefaults?.AgeTo || 0,
            ExperienceYearsFrom: dataDefaults?.ExperienceYearsFrom || 0,
            ExperienceYearsTo: dataDefaults?.ExperienceYearsTo || 0,
            DesiredSalaryFrom: dataDefaults?.DesiredSalaryFrom || 0,
            DesiredSalaryTo: dataDefaults?.DesiredSalaryTo || 0,
            SexID: dataDefaults?.SexID && dataDefaults.SexID.length > 0 ? dataDefaults.SexID : [],
        };
    };

    handleChange = (nameField, e) => {
        if (!nameField) return false;
        const {AgeFrom, AgeTo,ExperienceYearsFrom, ExperienceYearsTo, DesiredSalaryFrom, DesiredSalaryTo} = this.state;
        if (nameField === "DesiredSalaryFrom" && Number(e.value) < 0) {
            e.value = 0;
        }

        if (nameField === "DesiredSalaryTo" && Number(e.value) < 0) {
            e.value = 0;
        }

        if(nameField === "AgeFrom") {
            if (Number(e.target.value) > Number(AgeTo)) {
                this.setStateErrorText({"AgeFrom": Config.lang("DHR_Tuoi_tu_khong_duoc_lon_hon_tuoi_den")});
            } else {
                this.setStateErrorText({"AgeFrom": "","AgeTo": ""});
            }
        }

        if(nameField === "AgeTo") {
            if (Number(e.target.value) < Number(AgeFrom)) {
                this.setStateErrorText({"AgeTo": Config.lang("DHR_Tuoi_den_khong_duoc_nho_hon_tuoi_tu")});
            } else {
                this.setStateErrorText({"AgeFrom": "","AgeTo": ""});
            }
        }

        if(nameField === "ExperienceYearsFrom") {
            if (Number(e.target.value) > Number(ExperienceYearsTo)) {
                this.setStateErrorText({"ExperienceYearsFrom": Config.lang("DHR_Kinh_nghiem_tu_khong_duoc_lon_hon_kinh_nghiem_den")});
            } else {
                this.setStateErrorText({"ExperienceYearsFrom": "","ExperienceYearsTo": ""});
            }
        }

        if(nameField === "ExperienceYearsTo") {
            if (Number(e.target.value) < Number(ExperienceYearsFrom)) {
                this.setStateErrorText({"ExperienceYearsTo": Config.lang("DHR_Kinh_nghiem_den_khong_duoc_nho_hon_kinh_nghiem_tu")});
            } else {
                this.setStateErrorText({"ExperienceYearsFrom": "","ExperienceYearsTo": ""});
            }
        }

        if(nameField === "DesiredSalaryFrom") {
            if (Number(e.value) > Number(DesiredSalaryTo)) {
                this.setStateErrorText({"DesiredSalaryFrom": Config.lang("DHR_So_tien_tu_khong_duoc_lon_hon_so_tien_den")});
            } else {
                this.setStateErrorText({"DesiredSalaryFrom": "","DesiredSalaryTo": ""});
            }
        }
        if(nameField === "DesiredSalaryTo") {
            if (Number(e.value) < Number(DesiredSalaryFrom)) {
                this.setStateErrorText({"DesiredSalaryTo": Config.lang("DHR_So_tien_den_khong_duoc_nho_hon_so_tien_tu")});
            } else {
                this.setStateErrorText({"DesiredSalaryFrom": "","DesiredSalaryTo": ""});
            }
        }

        switch (nameField) {
            case "Candidate":
                this.filter.Candidate = e.target.value;
                break;
            case "PastRecruits":
                this.filter.PastRecruits = e.target.value;
                break;
            case "SexID":
                this.filter.SexID = e.value;
                break;
            case "RecSourceID":
                this.filter.RecSourceID = e.value;
                break;
            case "ReceivedDateFrom":
                this.setState({ReceivedDateFrom: e.value});
                this.filter.ReceivedDateFrom = e.value;
                break;
            case "ReceivedDateTo":
                this.setState({ReceivedDateTo : e.value});
                this.filter.ReceivedDateTo = e.value;
                break;
            case "AgeFrom":
                this.setState({AgeFrom: e.target.value});
                this.filter.AgeFrom = e.target.value;
                break;
            case "AgeTo":
                this.setState({AgeTo: e.target.value});
                this.filter.AgeTo = e.target.value;
                break;
            case "ExperienceYearsFrom":
                this.setState({ExperienceYearsFrom: e.target.value});
                this.filter.ExperienceYearsFrom = e.target.value;
                break;
            case "ExperienceYearsTo":
                this.setState({ExperienceYearsTo: e.target.value});
                this.filter.ExperienceYearsTo = e.target.value;
                break;
            case "DesiredSalaryFrom":
                this.setState({DesiredSalaryFrom: e.value});
                this.filter.DesiredSalaryFrom = e.value;
                break;
            case "DesiredSalaryTo":
                this.setState({DesiredSalaryTo: e.value});
                this.filter.DesiredSalaryTo = e.value;
                break;
            case "NotInRec":
                this.setState({NotInRec: e.target.checked});
                this.filter.NotInRec = e.target.checked;
                break;
            case "NotInInterview":
                this.setState({NotInInterview: e.target.checked});
                this.filter.NotInInterview = e.target.checked;
                break;
            default:
                break;
        }
    };

    loadGrid = (isReset) => {
        const {limit, skip, Candidate,PastRecruits,
            ReceivedDateFrom, ReceivedDateTo, AgeFrom, AgeTo, ExperienceYearsFrom,
            ExperienceYearsTo, DesiredSalaryFrom, DesiredSalaryTo,
            NotInInterview, NotInRec, RecSourceID,
            SexID} = this.filter;
        const {FormID} = this.props;
        const params = {
            FormID: FormID,
            Language : Config.language || 84,
            Candidate: Candidate ? Candidate : "",
            PastRecruits: PastRecruits ? PastRecruits : "",
            Sex: SexID && SexID.length > 0 ? JSON.stringify(SexID) : "",
            RecSourceID: RecSourceID && RecSourceID.length > 0 ? JSON.stringify(RecSourceID): "",
            ReceivedDateFrom: ReceivedDateFrom ? ReceivedDateFrom : null,
            ReceivedDateTo: ReceivedDateTo ? ReceivedDateTo : null,
            AgeFrom: AgeFrom ? Number(AgeFrom) : 0,
            AgeTo: AgeTo ? Number(AgeTo) : 0,
            ExperienceYearsFrom: ExperienceYearsFrom ? Number(ExperienceYearsFrom) : 0,
            ExperienceYearsTo: ExperienceYearsTo ? Number(ExperienceYearsTo) : 0,
            DesiredSalaryFrom: DesiredSalaryFrom ? DesiredSalaryFrom : 0,
            DesiredSalaryTo: DesiredSalaryTo ? DesiredSalaryTo : 0,
            NotInInterview: NotInInterview ? Number(NotInInterview) : 0,
            NotInRec: NotInRec ? Number(NotInRec) : 0,
            limit: limit,
            skip: skip
        };
        if (!isReset) {
            this.changePage = true;
        }
        const checkErr = Object.values(this.state.error).some(x => x !== "");
        if (checkErr) {
            return false;
        } else {
            this.props.w25F6000Actions.search(params, (errors, data) => {
                if (errors) {
                    let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                let selectedRowKeys = [];
                if (data.rows && data.rows.length > 0) {
                    data.rows.forEach((e) => {
                        if (this.tmpSelectedRowKeys.indexOf(e.CandidateID) > -1) {
                            selectedRowKeys.push(e.CandidateID);
                        }
                    });
                }
                this.allowSetDataDefault = true;
                if(this.allowSetDataDefault) {
                    const {dataSelected} = this.props;
                    if(dataSelected && Object.keys(dataSelected).length > 0){
                        const { tasks } = dataSelected;
                        if(tasks && Object.keys(tasks).length > 0) {
                            selectedRowKeys =  Object.keys(tasks).filter(val => !selectedRowKeys.indexOf(val) > -1);
                        }
                        this.tmpSelectedRowKeys = selectedRowKeys;
                        this.tmpSelectedRowData = selectedRowKeys;
                    }
                }
                this.setState({
                    dataGrid       : data.rows ? data.rows : data,
                    totalDataGrid  : data.total,
                    selectedRowKeys: selectedRowKeys
                },() => {
                    this.allowSetDataDefault = false;
                });
            });
        }
    };

    onFilter = () => {
        this.filter.skip = 0;
        this.loadGrid(true);
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (per) => {
        this.filter.skip = 0;
        this.filter.limit = per;
        this.loadGrid();
    };

    onSelect = () => {
        let selectedRowKeys = this.tmpSelectedRowKeys;
        let selectedRowData = this.tmpSelectedRowData;
        if (this.selectAll) {
            //chạy api để lấy tất cả....
            //rồi mới insert được
        }
        const {onChosen} = this.props;
        this.chooseOnSelect = true;
        this.tmpRemoveSelectedRowKeys = [];
        if(selectedRowKeys && selectedRowKeys.length > 0) {
            if (onChosen) onChosen({selectedRowKeys: selectedRowKeys, selectedRowData: selectedRowData});
        } else {
            Config.popup.show("INFO", Config.lang("DHR_Ban_chua_chon_du_lieu_tren_luoi?"));
        }
    };

    setSelectedCandidateID = (e) => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const { dataGrid } = this.state;
        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
            this.tmpRemoveSelectedRowKeys = this.tmpRemoveSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });

            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((d) => {
                return currentDeselectedRowKeys.indexOf(d.CandidateID) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
                if (this.tmpRemoveSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpRemoveSelectedRowKeys.push(val);
                }
            });
            dataGrid.forEach((val) => {
                const isExist = this.tmpSelectedRowData.filter((d) => d.CandidateID  === val.CandidateID );
                if (this.tmpSelectedRowKeys.indexOf(val.CandidateID) > -1 && isExist.length < 1) {
                    this.tmpSelectedRowData.push(val);
                }
            });
        }
    };

    onSelectionChanged = async (e) => {
        if (!this.isSelecting) {
            if (this.changePage) {
                this.changePage = false;
            } else {
                this.isSelecting = true;
                await this.setSelectedCandidateID(e);
                this.setState({
                    selectedRowKeys: e.selectedRowKeys
                }, () => {
                    this.isSelecting = false;
                });
            }
        }
    };

    setStateErrorText = (value) => {
        const { error } = this.state;
        this.setState({
            error: {
                ...error,
                ...value
            }
        });
        return Object.keys(value).length !== 0;
    };

    cellRenderSex = (e) => {
        const {data} = e;
        let name = "";
        if(data.Sex === 0) {
            name = Config.lang("DHR_NamU");
        } else {
            name = Config.lang("DHR_Nu");
        }
        return name;
    };

    onClose = () => {
        const { selectedRowKeys } = this.state;
        const {onClose} = this.props;
        if(this.chooseOnSelect) {
            if(this.tmpRemoveSelectedRowKeys && this.tmpRemoveSelectedRowKeys.length > 0) {
                const dataSelectedRowKeys = selectedRowKeys && selectedRowKeys.filter(f => !this.tmpRemoveSelectedRowKeys.includes(f));
                this.setState({selectedRowKeys: dataSelectedRowKeys});
                this.tmpSelectedRowKeys = dataSelectedRowKeys;
                this.tmpSelectedRowData = dataSelectedRowKeys;
            }
            this.chooseOnSelect = true;
        }

        if(onClose) onClose();
    };

    render() {
        const {classes, open, getCboRecruimentChanel} = this.props;
        const {loading, isSaving, dataGrid, totalDataGrid,
                  selectedRowKeys, minimum, error} = this.state;
        const {
                  loadingCboRecruitmentChanel, NotInInterview, NotInRec, ReceivedDateFrom, ReceivedDateTo
              } = this.state;
        const {
                Candidate,PastRecruits, AgeFrom, AgeTo, ExperienceYearsFrom,
                ExperienceYearsTo, DesiredSalaryFrom, DesiredSalaryTo, RecSourceID,
                SexID,
              } = this.filter;
        return (
            <React.Fragment>
                <Modal open={open} maxWidth={"lg"} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <ActionToolbar
                            alignment={"flex-end"}
                            title={Config.lang("DHR_danh_sach_ung_vien")}
                            showBorder={false}
                            upperCase={false}
                            className={"text-uppercase"}
                            style={{
                                marginTop: 0,
                            }}
                        >
                            <ButtonGeneral name={Config.lang('DHR_Dong1')}
                                           typeButton={'cancel'}
                                           color={"default"}
                                           variant={"outlined"}
                                           disabled={isSaving}
                                           className={"mgr5"}
                                           style={{textTransform: 'uppercase'}}
                                           size={"large"}
                                           onClick={this.onClose}/>
                            <ButtonGeneral name={Config.lang('DHR_Chon')}
                                           color={"primary"}
                                           variant={"contained"}
                                           loading={isSaving}
                                           style={{textTransform: 'uppercase'}}
                                           size={"large"}
                                           onClick={this.onSelect}/>
                        </ActionToolbar>
                    </Modal.Title>
                    <Divider />
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={4} md={4} lg={4} className={classes.panel + " " + (minimum && window.innerWidth > 768 ? classes.leftPanelMinimum : "")}>

                                <div className={classes.panelForm + " form-field " + (minimum ? "hide" : "")}>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('DHR_Ung_vien')}</label>
                                                <TextField
                                                    placeholder={Config.lang('DHR_Ma_ung_vien_Ten_ung_vien')}
                                                    variant={"outlined"}
                                                    defaultValue={Candidate}
                                                    onChange={(e) => this.handleChange('Candidate', e)}
                                                    fullWidth
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('DHR_Cong_viec_truoc_day')}</label>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder={Config.lang('DHR_Cong_viec_truoc_day')}
                                                    fullWidth
                                                    defaultValue={PastRecruits}
                                                    onChange={(e) => this.handleChange('PastRecruits', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('DHR_Gioi_tinh')}</label>
                                                <Combo
                                                    dataSource={this.genders}
                                                    displayExpr={'SexName'}
                                                    valueExpr={'SexID'}
                                                    multiple={true}
                                                    value={SexID}
                                                    margin={"dense"}
                                                    stylingMode={'outlined'}
                                                    placeholder={Config.lang('DHR_Gioi_tinh')}
                                                    onValueChanged={(e) => this.handleChange("SexID", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 text-uppercase"}>{Config.lang('DHR_Kenh_tuyen_dung')}</label>
                                                <Combo
                                                    dataSource={getCboRecruimentChanel}
                                                    displayExpr={'RecSourceName'}
                                                    valueExpr={'RecSourceID'}
                                                    margin={"dense"}
                                                    multiple={true}
                                                    value={RecSourceID}
                                                    stylingMode={'outlined'}
                                                    disabled={loadingCboRecruitmentChanel}
                                                    placeholder={Config.lang('DHR_Kenh_tuyen_dung')}
                                                    onValueChanged={(e) => this.handleChange("RecSourceID", e)}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 mgt5 text-uppercase"}>{Config.lang("DHR_Ngay_nhan_ho_so")}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Tu")}</Typography>
                                                    <DateBoxPicker
                                                        max={ReceivedDateTo}
                                                        placeholder={"dd/mm/yyyy"}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        value={ReceivedDateFrom}
                                                        width={"100%"}
                                                        onValueChanged={e => this.handleChange("ReceivedDateFrom", e)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Den")}</Typography>
                                                    <DateBoxPicker
                                                        min={ReceivedDateFrom}
                                                        placeholder={"dd/mm/yyyy"}
                                                        useMaskBehavior={true}
                                                        showClearButton={true}
                                                        value={ReceivedDateTo}
                                                        width={"100%"}
                                                        onValueChanged={e => this.handleChange("ReceivedDateTo", e)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 mgt5 text-uppercase"}>{Config.lang("DHR_Tuoi")}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Tu")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["AgeFrom"])} placement={"top"} disableFocusListener={!(error && error["AgeFrom"])} title={(error && error["AgeFrom"]) || ""}>
                                                        <TextField
                                                            error={error && error["AgeFrom"]}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textError}
                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                            fullWidth
                                                            defaultValue={AgeFrom ? String(AgeFrom) : ""}
                                                            type={"number"}
                                                            onChange={e => this.handleChange("AgeFrom", e)}
                                                        />
                                                    </Tooltip>

                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Den")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["AgeTo"])} disableFocusListener={!(error && error["AgeTo"])} placement={"top"} title={(error && error["AgeTo"]) || ""}>
                                                        <TextField
                                                            error={error && error["AgeTo"]}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textError}
                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                            fullWidth
                                                            type={"number"}
                                                            defaultValue={AgeTo ? String(AgeTo) : ""}
                                                            onChange={e => this.handleChange("AgeTo", e)}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 mgt5 text-uppercase"}>{Config.lang("DHR_So_nam_kinh_nghiem")}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Tu")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["ExperienceYearsFrom"])} disableFocusListener={!(error && error["ExperienceYearsFrom"])} placement={"top"} title={(error && error["ExperienceYearsFrom"]) || ""}>
                                                        <TextField
                                                            error={error && error["ExperienceYearsFrom"]}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textError}
                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                            fullWidth
                                                            defaultValue={ExperienceYearsFrom ? ExperienceYearsFrom : ""}
                                                            type={"number"}
                                                            onChange={e => this.handleChange("ExperienceYearsFrom", e)}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Den")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["ExperienceYearsTo"])} disableFocusListener={!(error && error["ExperienceYearsTo"])} placement={"top"} title={(error && error["ExperienceYearsTo"]) || ""}>
                                                        <TextField
                                                            error={error && error["ExperienceYearsTo"]}
                                                            variant="outlined"
                                                            size="small"
                                                            className={classes.textError}
                                                            // InputProps={{ inputProps: { min: 0 } }}
                                                            fullWidth
                                                            defaultValue={ExperienceYearsTo ? ExperienceYearsTo : ""}
                                                            type={"number"}
                                                            onChange={e => this.handleChange("ExperienceYearsTo", e)}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <label className={"mgb0 mgt5 text-uppercase"}>{Config.lang("DHR_Luong_yeu_cau")}</label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Tu")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["DesiredSalaryFrom"])} disableFocusListener={!(error && error["DesiredSalaryFrom"])} placement={"top"} title={(error && error["DesiredSalaryFrom"]) || ""}>
                                                        <NumberFormat
                                                            error={error && error["DesiredSalaryFrom"]}
                                                            className={classes.numberInput + " " + classes.textError}
                                                            customInput={TextField}
                                                            decimalScale={0}

                                                            thousandSeparator={true}
                                                            value={DesiredSalaryFrom ? DesiredSalaryFrom : ""}
                                                            onValueChange={e => this.handleChange("DesiredSalaryFrom", e)}
                                                            fullWidth
                                                            variant={"outlined"}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6} lg={6}>
                                                <div className={"display_row align-center"}>
                                                    <Typography className={"mgb0 mgr5"}>{Config.lang("DHR_Den")}</Typography>
                                                    <Tooltip disableHoverListener={!(error && error["DesiredSalaryTo"])} disableFocusListener={!(error && error["DesiredSalaryTo"])} placement={"top"} title={(error && error["DesiredSalaryTo"]) || ""}>
                                                        <NumberFormat
                                                            error={error && error["DesiredSalaryTo"]}
                                                            className={classes.numberInput + " " + classes.textError}
                                                            customInput={TextField}
                                                            decimalScale={0}
                                                            thousandSeparator={true}
                                                            value={DesiredSalaryTo ? DesiredSalaryTo : ""}
                                                            onValueChange={e => this.handleChange("DesiredSalaryTo", e)}
                                                            fullWidth
                                                            variant={"outlined"}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup style={{marginBottom: 5}}>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <div className={"display_row align-center"}>
                                                    <FormControlLabel
                                                        // disabled={loadingForm}
                                                        control={
                                                            <Checkbox
                                                                checked={NotInRec}
                                                                onChange={e => this.handleChange("NotInRec", e)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={Config.lang("DHR_Chua_thuoc_tin_tuyen_dung_nao")}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <div className={"display_row align-center"}>
                                                    <FormControlLabel
                                                        // disabled={loadingForm}
                                                        control={
                                                            <Checkbox
                                                                checked={NotInInterview}
                                                                onChange={e => this.handleChange("NotInInterview", e)}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={Config.lang("DHR_Chua_co_lich_phong_van_nao")}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup className={"display_row align_center valign-bottom"}>
                                        <ButtonGeneral name={Config.lang('DHR_Tim_kiem')}
                                                       typeButton={'search'}
                                                       color={"primary"}
                                                       className={"pdl10 pdr10"}
                                                       variant={"outlined"}
                                                       style={{textTransform: 'uppercase'}}
                                                       size={"large"}
                                                       onClick={this.onFilter}/>
                                    </FormGroup>
                                </div>
                            </Col>
                            <Col xs={12} sm={8} md={8} lg={8} className={classes.panel + " " + (window.innerWidth > 768 ? "bdl " + (minimum ? classes.rightPanelMinimum : "") : "")}>
                                <FormGroup>
                                    <GridContainer
                                        totalItems={totalDataGrid}
                                        itemPerPage={this.filter.limit}
                                        skipPerPage={this.filter.skip}
                                        listPerPage={[15, 30, 45, 60]}
                                        dataSource={dataGrid}
                                        disabled={isSaving}
                                        keyExpr={'CandidateID'}
                                        gridProps={{
                                            style: {
                                                minHeight: 400,
                                            }
                                        }}
                                        pagerFullScreen={false}
                                        showBorders={false}
                                        columnAutoWidth={true}
                                        typeShort={window.innerWidth < 768}
                                        loadPanel={{
                                            enabled: loading
                                        }}
                                        height={"calc(100vh - 180px)"}
                                        selection={{
                                            allowSelectAll: true,
                                            mode: "multiple",
                                            selectAllMode: "allPages",
                                            showCheckBoxesMode: "always"
                                        }}
                                        allowColumnResizing={true}
                                        selectedRowKey={selectedRowKeys}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        onSelectionChanged={this.onSelectionChanged}
                                    >
                                        <Column
                                            caption={Config.lang("DHR_Ma_UV")}
                                            dataField={'CandidateID'}
                                            alignment={"left"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ten_UV")}
                                            dataField={'CandidateName'}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ngay_sinh")}
                                            dataField={'BirthDate'}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Gioi_tinh")}
                                            dataField={'Sex'}
                                            width={100}
                                            cellRender={this.cellRenderSex}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Ngay_nhan_ho_so")}
                                            dataField={'ReceivedDate'}
                                            alignment={"center"}
                                            dataType={"date"}
                                            format={"dd/MM/yyyy"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Email")}
                                            dataField={'Email'}
                                            width={200}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Dien_thoai")}
                                            dataField={'Mobile'}
                                            alignment={"center"}
                                            width={150}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Vi_tri_tuyen")}
                                            alignment={"center"}
                                            dataField={'RecPositionName'}
                                        />
                                    </GridContainer>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

W25F6000.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,

    /**
     * ProjectID: ""
     * DutyID: ""
     * ...
     * selectedRowKeys: [EmpID, EmpID]
     * isLoaded: false
     */
    dataDefaults: PropTypes.object,

    onClose: PropTypes.func,
    onChosen: PropTypes.func
};

export default compose(connect((state) => ({
    getCboRecruimentChanel: state.W25F6000.getCboRecruimentChanel
}), (dispatch) => ({
    w25F6000Actions: bindActionCreators(W25F6000Actions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W25F6000);