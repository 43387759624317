/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 6/24/2020
 * @Example
 */
import React, { Component } from "react";
import Config from "../../../../config";

import GridContainer from "../../../grid-container/grid-container";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { FormGroup, Col, Row } from "react-bootstrap";
import _ from "lodash";
import { Column } from "devextreme-react/data-grid";
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { Combo } from "../../../common/form-material";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import Modal from "../../../common/modal/modal";
import withStyles from "@material-ui/core/styles/withStyles";
import { FormLabel as Label, Chip, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import * as W29F2010Actions from "../../../../redux/W2X/W29F2010/W29F2010_actions";
import * as generalActions from "../../../../redux/general/general_actions";
import W29F2012 from "../W29F2012/W29F2012";

import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import GridActionBar from "../../../grid-container/grid-actionbar";
import Api from "../../../../services/api";
import Icons from "../../../common/icons/";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005"
import HistoryIcon from '@material-ui/icons/History';
import W29F2045 from "./../W29F2045/W29F2045"
const styles = {
    root: {
        marginBottom: 15,
        borderRadius: 0,
        boxShadow: "none",
        backgroundColor: "#F3F3F3",
    },
    labelCheckbox: {
        fontSize: "0.8rem",
    },
    checkbox: {
        padding: "0 4px",
    },
    tab: {
        maxWidth: "fit-content",
        padding: "15px 58px",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    tabActive: {
        backgroundColor: "#fff",
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase",
    },
    avatarEmployees: {
        display: "flex",
        alignItems: "center",
        "& .user-picture-image": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: 28,
            minWidth: 28,
        },
    },
    hoverAction: {
        minHeight: "53px !important",
    },
};
class W29F2010 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            dataFormGrid: [],
            DateFrom: null,
            DateTo: null,
            mode: "add",
            rowData: null,
            showW09F2012: false,
            isUseOTType: 0,
            disabled: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
            showW29F2045Popup: false,
            w29F2045PopupMode:"add",
            w29F2045PopupData: {
                TransID: null
            },
        };
        this.defaultParams = {
            FormID: "W29F2010",
            Language: Config.language || "84",
        };
        this.paramFilters = {
            AppStatusID: "",
            DepartmentID: "",
            EmployeeID: "",
            FieldSort: "",
            TypeSort: "",
            Mode: 0,
            skip: 0,
            limit: 10,
        };
    }

    loadFormGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const { DateFrom, DateTo } = this.state;
        const params = {
            DateFrom,
            DateTo,
            ...this.defaultParams,
            ...this.paramFilters,
            TransID: VoucherID || ""
        };
        this.setState({ gridLoading: true, disabled: true });
        this.props.w29f2010Actions.getFormGrid(params, (err) => {
            if (err) Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            this.setState({ gridLoading: false, disabled: false });
        });
    };

    loadCboAppStatus = () => {
        this.setState({ cbLoading: true });
        this.props.generalActions.getCboAppStatus({ ...this.defaultParams, FormID: "STANDARD" }, (err) => {
            if (err) Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            this.setState({ cbLoading: false });
        });
    };
    onHistoryApproval = (e, data) => {
        this.setState({ w84F3005PopupData: { TransID: data?data.TransID:null }, showW84F3005Popup: true })
    }
    onAddActOTHour =(data)=>{
        let w29F2045PopupData =data?{...data,LinkOTTransID:data.TransID}:{}
        this.setState({showW29F2045Popup:true,w29F2045PopupMode:"add",w29F2045PopupData})
    }
    checkOTType = () => {
        Api.get("/w29f2010/check-use-ottype").then((res) => {
            this.setState({ isUseOTType: _.get(res, "data.IsUseOTType", 0) });
        });
    };

    renderStatus = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        const color =
            Number(data.AppStatusID) === 0 || Number(data.AppStatusID) === 1 ? "black" : Number(data.AppStatusID) === 4 ? "red" : "green";
        return <Chip label={data.AppStatusName} className={classes.chip} style={{ color: color }} variant={"outlined"} />;
    };

    renderEmpProfile = (e) => {
        const { data } = e.row;
        const employeeId = _.get(data, "EmployeeID", "");
        return (
            <div style={{display: "flex", alignItems: "center" }}>
                <UserImage width={38} height={38} data={data} />
                {employeeId}
            </div>
        );
    };

    renderEmpName = (e) => {
        const { data } = e.row;
        return <UserName  data={data}/>
    };

    renderButtonAction = (e) => {
        const allowClick = Boolean(_.get(e, "data.AppStatusID", 1));
        const {IsAddActOTHour,}  =e&&e.data?e.data:{}
        return (
            <GridActionBar>
                <div className="">
                <IconButton

                        aria-label="history"
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => this.onHistoryApproval(null,e&&e.data)}
                    > <Tooltip title={Config.lang('DHR_Lich_su_duyet')}>
                        <HistoryIcon/>
                        </Tooltip>
                    </IconButton>
                    <IconButton
                        disabled={allowClick}
                        aria-label="delete"
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => this.onEdit(e)}
                    >
                         <Tooltip title={Config.lang('DHR_Sua')}><div>
                        <Icons name={"edit"}/>
                        </div></Tooltip>
                    </IconButton>
                    <IconButton
                        disabled={allowClick}
                        aria-label="delete"
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => {
                            Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                                this.onDeleteData(e);
                            });
                        }}
                    >
                         <Tooltip title={Config.lang('DHR_Xoa')}><div>
                        <Icons name={"delete"}/>
                        </div></Tooltip>
                    </IconButton>
                    {
                        IsAddActOTHour===1&&
                        <IconButton
                            aria-label={Config.lang('DHR_Dang_ky_gio_tang_ca_thuc_te')}
                            style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                            onClick={()=>this.onAddActOTHour(e&&e.data)}
                        ><Tooltip title={Config.lang('DHR_Dang_ky_gio_tang_ca_thuc_te')}><div>
                            <Icons name={"circle-plus"}/>
                            </div></Tooltip>
                        </IconButton>
                        
                    }
                </div>
            </GridActionBar>
        );
    };

    renderFilters = () => {
        const { DateFrom, DateTo } = this.state;
        const { classes, getCboAppStatus } = this.props;
        return (
            <Filter
                placeholder={Config.lang("DHR_Noi_dung_can_tim_kiem")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                                            <DateBoxPicker
                                                placeholder={"DD/MM/YYYY"}
                                                max={DateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateFrom}
                                                onValueChanged={(e) => this.handleFilterChange("DateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                placeholder={"DD/MM/YYYY"}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={DateTo}
                                                onValueChanged={(e) => this.handleFilterChange("DateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={(e) => this.handleFilterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={this.paramFilters.AppStatusID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    className={classes.btnFilter}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={() => {
                                        this.loadFormGrid();
                                    }}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    handleFilterChange = (key, data) => {
        const stateFieldName = ["DateTo", "DateFrom"];
        const value = _.get(data, "value", "");
        if (stateFieldName.includes(key)) {
            this.setState({
                [key]: value,
            });
            return;
        }
        this.paramFilters[key] = value;
    };

    onDeleteData = (e) => {
        const LinkOTTransID = _.get(e, "data.LinkOTTransID", "")
        const params = {
            TransID: _.get(e, "data.TransID", ""),
        };
        const _deleteCb = (err, res) => {
            if (err) {
                Config.popup.show("INFO", _.get(err, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
                return;
            }
            if (!_.get(res, "status", 1)) {
                Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 2000);
            } else {
                Config.popup.show("INFO", _.get(res, "message", Config.lang("DHR_Loi_chua_xac_dinh")));
            }
            this.loadFormGrid()
        }
        if (LinkOTTransID==="") {
            this.props.w29f2010Actions.deleteData(params, _deleteCb);
        }else{
            this.props.w29f2010Actions.deleteActOTHour(params, _deleteCb);
        }
        
    };

    onLoadDataFilter = () => {
        this.loadCboAppStatus();
    };

    onChangePage = (page) => {
        this.paramFilters.skip = page * this.paramFilters.limit;
        this.loadFormGrid();
    };

    onChangePerPage = (perPage) => {
        this.paramFilters.skip = 0;
        this.paramFilters.limit = perPage;
        this.loadFormGrid();
    };

    onOrderChanged = (e) => {
        if (!e || !e.column) return false;
        this.paramFilters.FieldSort = e.column.dataField;
        this.paramFilters.TypeSort = e.typeSort;
        this.loadFormGrid();
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            rowData: [],
            showW09F2012: true,
        });
    };

    onView = (e) => {
        const { data } = e.row;
         if (data && data.LinkOTTransID) {
            this.setState({
                w29F2045PopupMode: "view",
                w29F2045PopupData: data,
                showW29F2045Popup: true,
            });
            return 
        }
        this.setState({
            mode: "view",
            rowData: data,
            showW09F2012: true,
        });
    };

    onEdit = (e, isNotify) => {
        const { data } = e.row;
        if (data && data.LinkOTTransID) {
            this.setState({
                w29F2045PopupMode: "edit",
                w29F2045PopupData: data,
                showW29F2045Popup: true,
            });
            return 
        }
        this.setState({
            mode: isNotify ? "view" : "edit",
            rowData: data,
            showW09F2012: true,
        });
    };

    onCloseModal = (isReload = false) => {
        isReload && this.loadFormGrid();
        this.setState({
            showW09F2012: false,
        });
    };
    onCloseW29F2045Popup=(isReload)=>{
        isReload && this.loadFormGrid();
        this.setState({
            showW29F2045Popup: false,
            w29F2045PopupData:{}
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getFormGrid} = this.props;
        Config.callChildForm({
            FormID: "W29F2010",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: getFormGrid && getFormGrid.rows ? getFormGrid.rows : [],
            onLoad: (params) => this.loadFormGrid(params && params.ID),
            onAction: (e, data) => {
                if (Boolean(_.get(data, "AppStatusID", 1))) {
                    this.onView({row: {data: data}});
                } else {
                    this.onEdit({row: {data: data}});
                }
            }
        }, this.props);
    }

    getInfo = (props) => {
        if (!props) return {};
        const { location } = props ? props : {};
        const url = new window.URLSearchParams(window.location.search);
        if (url && url.get("voucher_id")) {
            return { ProTransID: url.get("voucher_id") };
        } else if (location && location.state) {
            return {
                ProTransID: location.state.voucher_id,
            };
        } else {
            return {};
        }
    };

    render() {
        const { skip, limit } = this.paramFilters;
        const { selectedRowKeys, gridLoading, disabled, isUseOTType, rowData, mode, showW09F2012,
            showW84F3005Popup, w84F3005PopupData = {},showW29F2045Popup,w29F2045PopupMode,w29F2045PopupData } = this.state;
        const { getFormGrid } = this.props;
        return (
            <FormGroup>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({ showW84F3005Popup: false })} FormID="W29F2010" TransID={w84F3005PopupData.TransID} />
                <W29F2045 disabledCboEmployee  open={showW29F2045Popup} mode ={w29F2045PopupMode} onClose={this.onCloseW29F2045Popup} FormID="W29F2010" data={w29F2045PopupData} />
                <ActionToolbar title={Config.lang("DHR_Tang_ca")}>
                    <ButtonGeneral
                        name={Config.lang("DHR_Them")}
                        typeButton={"add"}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        disabled={disabled}
                        onClick={this.onAdd}
                    />
                </ActionToolbar>
                <div className={"hidden"}>{this.renderFilters()}</div>
                <Modal
                    open={showW09F2012}
                    maxWidth={"md"}
                    fullWidth={true}
                    onClose={() => this.onCloseModal()}
                    title={Config.lang("DHR_Dang_ky_tang_ca")}
                >
                    <W29F2012 mode={mode} data={rowData}  onClose={(isReload) => this.onCloseModal(isReload)} />
                </Modal>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
                            <GridContainer
                                itemPerPage={limit}
                                skipPerPage={skip}
                                selection={{
                                    mode: "single",
                                }}
                                onDbCellClick={this.onView}
                                loading={gridLoading}
                                selectedRowKey={selectedRowKeys}
                                style={{ border: "none" }}
                                dataSource={_.get(getFormGrid, "rows", [])}
                                showBorders={false}
                                typePaging={"remote"}
                                totalItems={_.get(getFormGrid, "total", 0)}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onOrderChanged={this.onOrderChanged}
                            >
                                {Config.isMobile && (
                                    <Column
                                        width={80}
                                        alignment={"right"}
                                        allowEditing={false}
                                        cellRender={this.renderButtonAction}
                                        allowSorting={false}
                                    />
                                )}
                                <Column
                                    width={220}
                                    caption={Config.lang("DHR_Ma_nhan_vien")}
                                    dataField={"EmployeeID"}
                                    cellRender={this.renderEmpProfile}
                                    alignment={"left"}
                                    allowSorting={true}
                                />
                                <Column
                                    width={220}
                                    caption={Config.lang("DHR_Ten_nhan_vien")}
                                    dataField={"EmployeeName"}
                                    cellRender={this.renderEmpName}
                                    alignment={"left"}
                                    allowSorting={true}
                                />
                                <Column
                                    caption={Config.lang("DHR_Ngay_tang_ca")}
                                    dataField={"AttendanceDate"}
                                    alignment={"center"}
                                    dataType="date"
                                />
                                <Column caption={Config.lang("DHR_Tang_ca_tu")} dataField={"OTFrom"} alignment={"center"} />
                                <Column caption={Config.lang("DHR_Tang_ca_den")} dataField={"OTTo"} alignment={"center"} />
                                <Column caption={Config.lang("DHR_So_gio")} dataField={"OTHour"} alignment={"center"} />
                                <Column
                                    visible={isUseOTType}
                                    caption={Config.lang("DHR_Loai_tach_tang_ca")}
                                    dataField={"OTTypeName"}
                                    alignment={"center"}
                                />
                                <Column caption={Config.lang("DHR_Loai_ngay")} dataField={"WorkDayTypeName"} alignment={"center"} />
                                <Column caption={Config.lang("DHR_Ca_vao_ra")} dataField={"ShiftInOut"} alignment={"center"} />
                                <Column
                                    alignment={"center"}
                                    dataField={"AppStatusID"}
                                    caption={""}
                                    cellRender={this.renderStatus}
                                    minWidth={150}
                                    maxWidth={200}
                                />
                                <Column
                                    alignment={"center"}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    allowSorting={false}
                                    visible={!Config.isMobile}
                                    cellRender={this.renderButtonAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </FormGroup>
        );
    }

    componentDidMount = () => {
        this.checkOTType();
        this.loadFormGrid();
    };
}

export default compose(
    connect(
        (state) => ({
            getFormGrid: state.W29F2010.getFormGrid,
            getCboAppStatus: state.general.getCboAppStatus,
        }),
        (dispatch) => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w29f2010Actions: bindActionCreators(W29F2010Actions, dispatch),
        }),
    ),
    withStyles(styles),
)(W29F2010);
