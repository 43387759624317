/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 6/30/2020
 * @Example
 */
import React, {Component} from "react";

import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {Row, Col, FormGroup} from "react-bootstrap";
import {Column} from "devextreme-react/data-grid";
import _ from "lodash";
import InlineSVG from "react-inlinesvg";

import * as generalActions from "../../../../redux/general/general_actions";
import * as w29f2011Actions from "../../../../redux/W2X/W29F2011/W29F2011_actions";
import GridContainer from "../../../grid-container/grid-container";
import Config from "../../../../config";
import Filter from "../../../filter/filter";
import {Combo} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import {Divider, Icon} from "@material-ui/core";
import Modal from "../../../common/modal/modal";
import W29F2012 from "../../W29/W29F2012/W29F2012";
import W29F2011Popover from "./W29F2011Popover";
import GridActionBar from "../../../grid-container/grid-actionbar";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import W29F2016 from "../../../W2X/W29/W29F2016/W29F2016";
import HistoryIcon from '@material-ui/icons/History';

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        width: "100%",
        maxHeight: "100%",
        height: "100%",
        objectFit: "cover"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000"
    },
    chip: {
        fontSize: '1rem',
        fontWeight: 400,
        border: 'none',
        backgroundColor: '#EBEBEB',
    },
    iconHoverRed: {
        '&:hover': {
            color: '#FF405A',
        },
    },
    iconHoverGreen: {
        '&:hover': {
            color: '#1CA261',
        },
    },
    itemAtt: {
        borderRadius: 100,
        padding: '0 8px',
        border: '1px solid lightgrey',
        fontWeight: '400',
        marginRight: 10,
        fontSize: 12,
        height: 24,
        lineHeight: '22px',
        display: 'block',
        marginBottom: 10,
        '&:hover': {
            backgroundColor: '#E4E9F2'
        }
    },
    slick: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        position: 'absolute',
        top: "50%",
        zIndex: 2,
    },
    columnActions: {
        minHeight: "58px !important"
    }
};

class W29F2011 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            gridLoading: false,
            DateFrom: null,
            DateTo: null,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            loadingCboEmployees: false,
            open: false,
            anchorEl: null,
            type: "",
            showW29F2012: false,
            dataPopup: null,
            dataPopover: null,
            showW84F3005Popup: false,
            showW29F2016Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
        };
        this.loading = {
            cboProjects: false,
        };
        this.filter = {
            fieldSort: "",
            typeSort: "",
            dateFrom: null,
            dateTo: null,
            AppStatusID: "",
            DepartmentID: "",
            Employee: null,
            skip: 0,
            limit: 20
        };
        this.timer = null;
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.refPopover = null;
        this.isRenderModalW29F2016 = false;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W29F2016", isPer => {
            this.setState({ iPermission: isPer });
        });
    };


    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({["loading" + key]: status});
    };

    loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {DateFrom, DateTo} = this.state;
        const {skip, limit, AppStatusID, DepartmentID, Employee, fieldSort, typeSort} = this.filter;
        const params = {
            TransID: VoucherID || "",
            FormID: "W29F2011",
            Language: Config.language || 84,
            HostID: "",
            DateFrom: DateFrom,
            DateTo: DateTo,
            AppStatusID: AppStatusID,
            DepartmentID: DepartmentID,
            EmployeeID: Employee ? Employee.EmployeeID : "",
            VoucherDate: "",
            Mode: 0,
            skip: skip,
            limit: limit,
            FieldSort: fieldSort,
            TypeSort: typeSort,
        };
        this.setState({gridLoading: true});
        this.props.w29f2011Actions.getDataGrid(params, (error, data) => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };

    loadCboAppStatus = () => {
        const params = {
            Language: Config.language || '84',
            FormID: "W75F2005"
        };

        this.setState({gridLoading: true});
        this.props.generalActions.getCboAppStatus(params, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };

    loadCboDepartments = () => {
        const params = {
            DivisionID: Config.getDivisionID(),
            CompanyID: "",
            HostID: "",
            FormID: "W29F2011",
            Language: Config.language || '84'
        };

        this.setState({gridLoading: true});
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };

    loadGetOTTypeName = () => {
        this.setState({gridLoading: true});
        this.props.w29f2011Actions.getOTTypeName({}, error => {
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({gridLoading: false});
        });
    };


    loadCboEmployees = (isReset) => {
        const {skip, limit, strSearch} = this.filterCboEmployees;
        const params = {
            HostID: "",
            FormID: "W29F2011",
            Language: Config.language || '84',
            Mode: 0,
            skip: skip,
            limit: limit,
            search: strSearch
        };

        this.setLoading({"CboEmployees": true});
        this.setState({loading: this.loading});
        this.props.generalActions.getCboEmployees(params, (error, data) => {
            this.setLoading({"CboEmployees": false});
            this.setState({loading: this.loading});
            if (error) {
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };


    componentDidMount = async () => {
        await this.loadPermission();
        // if (!this.state.iPermission) return;
        this.loadCboAppStatus();
        this.loadCboDepartments();
        this.loadCboEmployees();
        this.loadGetOTTypeName();
        this.loadDataGrid();

    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getDataGrid} = this.props;
        Config.callChildForm({
            FormID: "W29F2011",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onDbCellClick({ row: { data: data } })
        }, this.props);
    }

    filterChange(key, data) {
        if (!key) return false;
        const value = _.get(data, "value", "");
        switch (key) {
            case "DateFrom":
                this.setState({DateFrom: value});
                break;
            case "DateTo":
                this.setState({DateTo: value});
                break;
            case "DepartmentID":
                this.filter.DepartmentID = value;
                break;
            case "EmployeeID":
                this.filter.Employee = value;
                break;
            case "AppStatusID":
                this.filter.AppStatusID = value;
                break;

            default:
                break;
        }
    };

    onHistoryApproval = (e, data) => {
        this.setState({w84F3005PopupData: {TransID: data ? data.TransID : null}, showW84F3005Popup: true})
    }
    onSearch = () => {
        this.loadDataGrid();
    };

    onChangePage = page => {
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = perPage => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    renderAvatar = e => {
        const data = _.get(e, "data", "");
        return (
            <div className={"display_row align-center"}>
                <UserImage data={data} width={32} height={32}/>
            </div>
        );
    };

    renderAction = (e) => {
        const {classes} = this.props;
        const {data} = e.row;
        const appStatusID = Number(_.get(data, "AppStatusID", 10))
        const arrBtnAction = _.filter([
            {
                classesName: "",
                onAction: this.onHistoryApproval,
                iconName: "HistoryIcon",
                isVisible: appStatusID === 0 || appStatusID === 2 || appStatusID === 4
            },
            {
                classesName: classes.iconHoverGreen,
                onAction: this.onOpenCheck,
                iconName: "fas fa-check",
                isVisible: appStatusID === 0
            },
            {
                classesName: classes.iconHoverRed,
                onAction: this.onOpenClose,
                iconName: "fas fa-times",
                isVisible: appStatusID === 0
            },
            {
                classesName: "",
                onAction: this.onOpenCancel,
                iconName: "Cancel",
                isVisible: appStatusID === 2 || appStatusID === 4
            },
        ], item => Boolean(item.isVisible));
        return (
            <GridActionBar>
                <div className={""}>
                    <div className={"display_row align-center valign-middle"}>
                        {_.map(arrBtnAction, (item, i) =>
                            <React.Fragment key={i}>
                                <IconButton aria-label="view"
                                            size="small"
                                            className={item.classesName}
                                            onClick={(e) => item.onAction(e, data)}>
                                    {item.iconName === "HistoryIcon" ? <HistoryIcon/> : item.iconName  === "Cancel" ? <InlineSVG src={require('../../../../assets/images/general/cancel.svg')}/> : <Icon className={item.iconName} fontSize={"small"}/> }
                                </IconButton>
                                {_.size(arrBtnAction) - 1 === i ? "" : <Divider orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}/>}
                            </React.Fragment>
                        )}
                        {/*<IconButton aria-label="view"*/}
                        {/*            size="small"*/}
                        {/*            className={classes.iconHoverGreen}*/}
                        {/*            onClick={(e) => this.onOpenCheck(e, data)}>*/}
                        {/*    <Icon className="fas fa-check" fontSize={"small"}/>*/}
                        {/*</IconButton>*/}
                        {/*<Divider*/}
                        {/*    orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}*/}
                        {/*/>*/}
                        {/*<IconButton aria-label="view"*/}
                        {/*            size="small"*/}
                        {/*            className={classes.iconHoverRed}*/}
                        {/*            onClick={(e) => this.onOpenClose(e, data)}>*/}
                        {/*    <Icon className="fas fa-times" fontSize={"small"}/>*/}
                        {/*</IconButton>*/}
                    </div>
                    {/* {data && Number(data.AppStatusID) === 2 && Number(data.IsNotApproval) === 1 &&*/}
                    {/*     <div className={"display_row align-center valign-middle"}>*/}
                    {/*         <IconButton aria-label="view" size="small" onClick={() => this.onHistoryApproval(e, data)}>*/}
                    {/*             <HistoryIcon />*/}
                    {/*         </IconButton>*/}
                    {/*         <Divider*/}
                    {/*             orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}*/}
                    {/*         />*/}
                    {/*         <IconButton aria-label="view" size="small" onClick={(e) => this.onOpenCancel(e, data)}>*/}
                    {/*             <InlineSVG src={require('../../../../assets/images/general/cancel.svg')}/>*/}
                    {/*         </IconButton>*/}
                    {/*     </div>*/}
                    {/*}*/}
                </div>
            </GridActionBar>
        )
    };

    renderStatus = (e) => {
        const {classes} = this.props;
        const {data} = e.row;
        const color = Number(data.AppStatusID) === 0 ? "black" : (Number(data.AppStatusID) === 4 ? "red" : "green");
        return (
            <Chip label={data.AppStatusName}
                  className={classes.chip}
                  style={{color: color}}
                  variant={"outlined"}
            />

        );
    };
    renderName = e => {
        const {classes} = this.props;
        const {data} = e.row;
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                </div>
            </div>
        );
    };

    onOpenClose = (e, data) => {
        this.setState({
            open: true,
            anchorEl: e.currentTarget,
            type: "close",
            dataPopover: data
        }, () => {
            if (this.refPopover) {
                this.refPopover.onCheck()
            }
        });
    };

    onOpenCheck = (e, data) => {
        this.setState({
            open: true,
            anchorEl: e.currentTarget,
            type: "check",
            dataPopover: data
        }, () => {
            if (this.refPopover) {
                this.refPopover.onCheck()
            }
        });
    };

    onOpenCancel = (e, data) => {
        this.setState({
            open: true,
            anchorEl: e.currentTarget,
            type: "cancel",
            dataPopover: data
        }, () => {
            if (this.refPopover) {
                this.refPopover.onCheck()
            }
        });
    };

    onClose = () => {
        this.setState({
            open: false,
        })
    };

    onCloseModal = () => {
        this.setState({
            showW29F2012: false,
        })
    };

    showPopupW29F2016 = () => {
        this.setState({
            showW29F2016Popup: true
        });
        this.isRenderModalW29F2016 = true;
    };

    onClosePopupW29f2016 = (isLoaded = false) => {
        this.setState({
            showW29F2016Popup: false
        }, () => {
            if(isLoaded) {
                this.loadDataGrid();
            }
        });

    };


    renderFilter = () => {
        const {
            getCboAppStatus,
            getCboDepartments,
            classes,
        } = this.props;
        const {Employee, DepartmentID, AppStatusID} = this.filter;
        const {loadingCboEmployees, dataCboEmployees, DateFrom, DateTo} = this.state;

        return (
            <Filter
                showClearButton={true}
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>{Config.lang("DHR_Tu")}</div>
                                            <DateBoxPicker
                                                dateBoxProps={{max: DateTo}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateFrom || null}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("DHR_Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{min: DateFrom}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => this.filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateTo || null}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={AppStatusID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("DHR_Phong_ban")}
                                            dataSource={Config.storeDataSoureDevExtreme(getCboDepartments)}
                                            displayExpr={"DepartmentName"}
                                            valueExpr={"DepartmentID"}
                                            onValueChanged={e => this.filterChange("DepartmentID", e)}
                                            showClearButton={true}
                                            value={DepartmentID}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo.LoadMore
                                            dataSource={dataCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={Employee}
                                            stylingMode={'outlined'}
                                            loading={loadingCboEmployees}
                                            label={Config.lang('DHR_Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "38px",
                                    paddingBottom: "40px"
                                }}
                            >
                                <ButtonGeneral
                                    // disabled={!(iPermission >= 1)}
                                    name={Config.lang("DHR_Tim_kiem")}
                                    typeButton={'search'}
                                    color={"primary"}
                                    className={"pdl10 pdr10"}
                                    variant={"outlined"}
                                    style={{textTransform: 'uppercase'}}
                                    size={"large"}
                                    onClick={this.onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    onOrderChanged = (e) => {
        if (!e || !e.column) return false;
        this.filter.fieldSort = e.column.dataField;
        this.filter.typeSort = e.typeSort;
        this.loadDataGrid();
    };

    onDbCellClick = (e) => {
        const {data} = e.row;
        this.setState({
            showW29F2012: true,
            dataPopup: data
        });
    };


    render() {
        const {
            gridLoading, showW29F2012, dataPopup, anchorEl, type, dataPopover, open,
            showW84F3005Popup, w84F3005PopupData = {}, showW29F2016Popup, iPermission,
        } = this.state;
        // if (iPermission <= 0) return null;
        const {skip, limit} = this.filter;
        const {classes, getDataGrid, getOTTypeName} = this.props;

        const dataRows = _.get(getDataGrid, "rows", []);
        const totalRow = _.get(getDataGrid, "total", 0);
        return (

            <>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({showW84F3005Popup: false})}
                          FormID="W29F2011" TransID={w84F3005PopupData.TransID}/>
                {this.isRenderModalW29F2016 &&
                    <W29F2016 open={showW29F2016Popup}
                              onClose={(isLoaded) => this.onClosePopupW29f2016(isLoaded)}
                              FormID="W29F2011"/>}
                <Modal open={showW29F2012} maxWidth={"md"} fullWidth={true}
                       onClose={this.onCloseModal}
                       component={
                           <W29F2012 mode={"view"} data={dataPopup}/>
                       }
                />
                <W29F2011Popover
                    ref={ref => this.refPopover = ref}
                    open={open}
                    onClose={this.onClose}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    funcLoadGrid={this.loadDataGrid}
                />
                <FormGroup>
                    <ActionToolbar title={Config.lang("DHR_Duyet_tang_ca")}>
                        {iPermission > 1 &&
                        <ButtonGeneral
                            name={Config.lang("DHR_Duyet_hang_loat")}
                            // disabled={isSaving}
                            color={"primary"}
                            variant={"contained"}
                            className={"mgr5"}
                            style={{ textTransform: "uppercase" }}
                            size={"large"}
                            onClick={this.showPopupW29F2016}
                        />
                        }
                    </ActionToolbar>
                    <div className={"hidden"}>{this.renderFilter()}</div>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
                                <GridContainer
                                    itemPerPage={limit}
                                    skipPerPage={skip}
                                    loading={gridLoading}
                                    style={{border: "none"}}
                                    dataSource={dataRows}
                                    showBorders={false}
                                    typePaging={"remote"}
                                    totalItems={totalRow}
                                    selection={{
                                        mode: "single"
                                    }}
                                    onDbCellClick={e => this.onDbCellClick(e)}
                                    onOrderChanged={this.onOrderChanged}
                                    height={Config.getHeightGrid()}
                                    typeShort={window.innerWidth < 768}
                                    showColumnLines={false}
                                    hoverStateEnabled={true}
                                    onChangePage={this.onChangePage}
                                    onChangePerPage={this.onChangePerPage}
                                >
                                    <Column
                                        cellRender={this.renderAvatar}/>
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Ma_NV")}
                                        dataField={"EmployeeID"}
                                        alignment={"left"}
                                    />
                                    <Column
                                        caption={Config.lang("DHR_Ten_nhan_vien")}
                                        dataField={"EmployeeName"}
                                        cellRender={this.renderName}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Ngay_tang_ca")}
                                        dataField={"AttendanceDate"}
                                        alignment={"center"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Tang_ca_tu")}
                                        dataField={"OTFrom"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Tang_ca_den")}
                                        dataField={"OTTo"}
                                        alignment={"left"}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_So_gio")}
                                        dataField={"OTHour"}
                                        alignment={"center"}
                                    />
                                    {getOTTypeName.IsUseOTType === 1 &&
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Loai_tach_tang_ca")}
                                        dataField={"OTTypeName"}
                                    />}
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Loai_ngay")}
                                        dataField={"WorkDayTypeName"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        caption={Config.lang("DHR_Ca_vao_ra")}
                                        dataField={"ShiftInOut"}
                                        alignment={"center"}
                                    />
                                    <Column
                                        style={{backgroundColor: "#b3bbc1"}}
                                        cssClass={classes.columnActions}
                                        caption={Config.lang("DHR_Trang_thai")}
                                        cellRender={this.renderStatus}
                                    />
                                    <Column
                                        alignment={"center"}
                                        fixed={true}
                                        fixedPosition={"right"}
                                        allowEditing={false}
                                        cellRender={this.renderAction}
                                    />
                                </GridContainer>
                            </Col>
                        </Row>
                    </FormGroup>
                </FormGroup>
            </>
        );
    }
}

export default compose(
    connect(
        state => ({
            getCboEmployees: state.general.getCboEmployees,
            getCboAppStatus: state.general.getCboAppStatus,
            getCboDepartments: state.general.getCboDepartments,
            getDataGrid: state.W29F2011.getDataGrid,
            getOTTypeName: state.W29F2011.getOTTypeName
        }),
        dispatch => ({
            w29f2011Actions: bindActionCreators(w29f2011Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        })
    ),
    withStyles(styles)
)(W29F2011);
