/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 7/7/2021
 * @Example
 */

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    panel: {
        transition: 'all .4s',
    },
    panelForm: {
        display: (props) => (props.minimum ? 'none' : ''),
        opacity: 1,
        transition: 'all .5s',
    },
    hiddenOpacity: {
        opacity: 0,
    },
    actionToolbar: {
        textTransform: 'uppercase !important',
        marginTop: '0px !important',
    },
    textUppercase: {
        textTransform: 'uppercase !important',
    },
    actionToolbarFilter: {
        marginTop: '0px !important',
        backgroundColor: (props) => (props.minimum ? theme.palette.primary.main : '#F7F9FC'),
        '& svg': {
            '& path': {
                fill: (props) => (props.minimum ? theme.palette.primary.contrastText : ''),
            },
        },
        padding: '0 15px',
        marginBottom: 15,
        fontSize: '1rem',
    },
    flex: {
        display: 'flex',
    },
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    gap10: {
        gap: 10,
    },
    pointerCursor: {
        cursor: 'pointer',
    },
    justifyBetween: {
        justifyContent: 'space-between',
    },
    mgb15: {
        marginBottom: 15,
    },
    colDateTo: {
        [theme.breakpoints.down(768)]: {
            marginTop: 5,
        },
    },
    leftCol: {
        width: (props) => (props.minimum ? '84px' : ''),
        [theme.breakpoints.down(768)]: {
            borderRight: 'none',
        },
    },
    rightCol: {
        width: (props) => (props.minimum ? 'calc(100% - 84px)' : ''),
        borderLeft: '1px solid #ddd',
    },
    justifyEnd: {
        justifyContent: 'flex-end',
    },
    mgr5: {
        marginRight: 5,
    },
    mgb0: {
        marginBottom: 0,
    },
    datePicker: {
        margin: 0,
        width: '100%',
        minWidth: 'unset',
    },
    mg0: {
        margin: 0,
    },
}));
