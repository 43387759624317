/**
 * @copyright 2019 @ DigiNet
 * @author THIEN
 * @create 12/2/2019
 * @Example
 */
import React, { Component } from "react";
import Config from "../../../../config";
import Api from "../../../../services/api";

import GridContainer from "../../../grid-container/grid-container";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { FormGroup, Col, Row } from "react-bootstrap";
import _ from "lodash";

import { Column } from "devextreme-react/data-grid";
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import { Combo } from "../../../common/form-material";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";

import IconButton from "@material-ui/core/IconButton";

import * as W29F2005Actions from "../../../../redux/W2X/W29F2005/W29F2005_actions";

import withStyles             from "@material-ui/core/styles/withStyles";
import { FormLabel as Label } from "@material-ui/core";
import { browserHistory } from "react-router";
import * as generalActions from "../../../../redux/general/general_actions";
import GridActionBar from "../../../grid-container/grid-actionbar";
import Icons from "../../../common/icons/";
import History                from "../../../libs/history";

const styles = {
  root: {
    marginBottom: 15,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "#F3F3F3"
  },
  labelCheckbox: {
    fontSize: "0.8rem"
  },
  checkbox: {
    padding: "0 4px"
  },
  tab: {
    maxWidth: "fit-content",
    padding: "15px 58px"
  },
  tabActive: {
    backgroundColor: "#fff"
  },
  btnFilter: {
    backgroundColor: "#9999993d",
    textTransform: "uppercase"
  }
};

class W29F2005 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iPermission: 0,
      selectedRowKeys: [],
      timeKeepingList: [],
      AttendanceDateFrom: null,
      AttendanceDateTo: null,
      loaded: true,
      dataCboProjects: {
        rows: [],
        total: 0
      },
      Project: null,
      loading: {
        cboProjects: false
      }
    };
    this.loading = {
      cboProjects: false
    };
    this.paramFilters = {
      handOverID: "",
      fieldSort: "",
      typeSort: "",
      skip: 0,
      limit: 10
    };
    this.filterCboProjects = {
      timer: null,
      strSearch: "",
      skip: 0,
      limit: 50
    };
  }

  loadPermission = async () => {
    await this.props.generalActions.getPermission("W29F2005", isPer => {
      this.setState({ iPermission: isPer });
    });
  };

  loadTimekeepingList = () => {
    const { handOverID, fieldSort, typeSort, skip, limit } = this.paramFilters;
    const { AttendanceDateFrom, AttendanceDateTo, Project } = this.state;
    const params = {
      ProjectID: Project && Project.ProjectID ? Project.ProjectID : "" ,
      HandOverID: handOverID,
      fieldSort,
      typeSort,
      skip,
      limit,
      AttendanceDateFrom,
      AttendanceDateTo
    };
    this.setState({ loaded: true });
    this.props.w29f2005Actions.getTimeKeepingList(params, (error, data) => {
      if (error) {
        let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        this.setState({
          loaded: false
        });
        return false;
      }
      this.setState({ timeKeepingList: data }, () => {
        this.setState({
          loaded: false
        });
      });
    });
  };

  loadCboProjects = (isReset) => {
    const params = {
      HostID: "",
      FormID: "W29F2005",
      Language: _.get(Config, "language", "84"),
      skip: this.filterCboProjects.skip,
      limit: this.filterCboProjects.limit,
      search: this.filterCboProjects.strSearch
    };
    this.loading.cboProjects = true;
    this.setState({loading: this.loading});
    this.props.w29f2005Actions.getCboProjects(params, (error, data) => {
      this.loading.cboProjects = false;
      this.setState({loading: this.loading});
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      if (data) {
        const {dataCboProjects} = this.state;
        const rows = data && data.rows ? data.rows : data;
        const total = data && data.total ? data.total : data.length;
        this.setState({
          dataCboProjects: {
            rows: isReset ? rows : dataCboProjects.rows.concat(rows),
            total: total
          }
        })
      }
    });
  };

  loadCboHandOver = () => {
    const { Project, AttendanceDateFrom } = this.state;
    const params = {
      ProjectID: Project && Project.ProjectID ? Project.ProjectID : "" ,
      AttendanceDateFrom: AttendanceDateFrom
    };
    this.props.w29f2005Actions.getCboHandOver(params, error => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
    });
  };

  loadDateByTran = () => {
    const params = {
      TranMonth: Config.getHRTransMonth(),
      TranYear: Config.getHRTransYear()
    };
    this.setState({ loaded: true });
    this.props.w29f2005Actions.getDateByTran(params, (error, data) => {
      if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
      const defaultDateForm = _.get(data, "MinDate", null);
      const defaultDateTo = _.get(data, "MaxDate", null);
      this.setState({
        AttendanceDateFrom: defaultDateForm,
        AttendanceDateTo: defaultDateTo,
        loaded: false
      });
    });
  };

  handleFilterChange = (key, data) => {
    if (!key) return false;
    const dataFilter = _.get(data, "value", "");
    switch (key) {
      case "dateForm":
        this.setState({ AttendanceDateFrom: dataFilter });
        break;
      case "dateTo":
        this.setState({ AttendanceDateTo: dataFilter });
        break;
      case "handoverID":
        this.paramFilters.handOverID = dataFilter;
        break;
      case "Project":
        this.setState({ Project: dataFilter }, () => {
          if (dataFilter && dataFilter.ProjectID) this.loadCboHandOver();
        });
        break;
      default:
        break;
    }
  };

  onLoadDataFilter = () => {
    this.loadCboProjects();
    this.loadDateByTran();
  };

  onFilter = () => {
    this.loadTimekeepingList();
  };

  redirectScreen = (mode, e = "") => {
    browserHistory.push({
      pathname: Config.getRootPath() + "W29F2006",
      state: {
        mode: mode,
        ...(e && e.data ? { AttendanceVoucherID: e.data.AttendanceVoucherID } : {})
      }
    });
  };

  checkStatusEditAndDelete = async (e, mode = 1) => {
    const { data: _data } = e;
    const validParams = {
      AttendanceVoucherID: _.get(_data, "AttendanceVoucherID", ""),
      Mode: mode.toString()
    };
    const params = {
      Language: "84",
      FormID: "W29F2005",
      ...validParams
    };
    const parseMode = mode ? "edit" : "delete";
    try {
      this.setState({ loaded: true });
      const res = (await Api.put("/w29f2005/check", params)) || "";
      if (res.data) {
        const { data } = res;
        if (data.Status) {
          let message = data.Message || "";
          if (data.MsgAsk) {
            Config.popup.show("YES_NO", message, async () => {
              if (Number(mode)) {
                this.redirectScreen(parseMode, e);
              } else {
                await this.deAttVoucher(validParams, _data);
              }
            });
          } else {
            Config.popup.show("INFO", message);
          }
        } else {
          await Number(mode)
            ? this.redirectScreen(parseMode, e)
            : Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_chac_chan_muon_xoa_du_lieu_nay"), async () => {
                await this.deAttVoucher(validParams, _data);
              });
        }
      }
    } catch (err) {
      let message = err.message || Config.lang("DHR_Loi_chua_xac_dinh");
      Config.popup.show("INFO", message);
    }
    this.setState({ loaded: false });
    return false;
  };

  deAttVoucher = async (params, data) => {
    this.setState({ loaded: true });
    this.props.w29f2005Actions.deleteAttendanceVoucher(params, async errors => {
      if (errors) {
        let message = errors.message || Config.lang("DHR_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      // Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 2000);
      await this.saveHistory(data);
      this.loadTimekeepingList();
    });
  };

  componentDidMount = async () => {
    await this.loadPermission();
    if (!this.state.iPermission) return;
    this.loadTimekeepingList();
  };

  componentWillUnmount = () => {
    this.setState({
      selectedRowKeys: [],
      timeKeepingList: [],
      AttendanceDateFrom: null,
      AttendanceDateTo: null,
      loaded: true
    });
  };

  renderFilters = () => {
    const { getCboHandOver } = this.props;
    const { AttendanceDateFrom, AttendanceDateTo, loaded, Project, dataCboProjects, loading } = this.state;
    const { handOverID } = this.paramFilters;
    return (
      <Filter
        disabled={loaded}
        onTextChanged={this.onSearch}
        placeholder={Config.lang("DHR_Tim_kiem_cham_cong")}
        onOpenLoaded={this.onLoadDataFilter}
        renderFilter={() => {
          return (
            <>
              <FormGroup>
                <Row>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div className={"display_row align-center"}>
                      <Label className={"mgr5"}>{Config.lang("DHR_Tu")}</Label>
                      <DateBoxPicker
                        max={AttendanceDateTo}
                        placeholder={"DD/MM/YYYY"}
                        useMaskBehavior={true}
                        showClearButton={true}
                        width={"100%"}
                        value={AttendanceDateFrom}
                        onValueChanged={e => this.handleFilterChange("dateForm", e)}
                      />
                    </div>
                  </Col>
                  <Col xs={12} sm={6} md={6} lg={6}>
                    <div className={"display_row align-center"}>
                      <Label className={"mgr5"}>{Config.lang("DHR_Den")}</Label>
                      <DateBoxPicker
                        min={AttendanceDateFrom}
                        placeholder={"DD/MM/YYYY"}
                        useMaskBehavior={true}
                        showClearButton={true}
                        width={"100%"}
                        value={AttendanceDateTo}
                        onValueChanged={e => this.handleFilterChange("dateTo", e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo.LoadMore
                        dataSource={dataCboProjects}
                        skip={this.filterCboProjects.skip}
                        limit={this.filterCboProjects.limit}
                        displayExpr={'ProjectName'}
                        keyExpr={'ProjectID'}
                        valueExpr={'ProjectID'}
                        value={Project}
                        loading={loading.cboProjects}
                        stylingMode={'outlined'}
                        label={Config.lang('DHR_Du_an')}
                        showClearButton={true}
                        itemRender={(e) => {
                          const {data} = e;
                          if (!data) return null;
                          return data.ProjectID + ' - ' + data.ProjectName;
                        }}
                        // showCheckBoxMode={"none"}
                        onValueChanged={(e) => this.handleFilterChange("Project", e)}
                        onInputChanged={(e) => {
                          this.filterCboProjects.strSearch = e.target.value;
                          this.filterCboProjects.skip = 0;
                          this.loadCboProjects(true);
                        }}
                        onLoadMore={(e) => {
                          this.filterCboProjects.skip = e.skip;
                          this.filterCboProjects.limit = e.limit;
                          this.loadCboProjects();
                        }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Combo
                      dataSource={Config.storeDataSoureDevExtreme(getCboHandOver)}
                      displayExpr={item => item && `${item.HandOverID} - ${item.HandOverName}`}
                      showClearButton={true}
                      valueExpr={"HandOverID"}
                      stylingMode={"outlined"}
                      label={Config.lang("DHR_Ban_giao")}
                      onValueChanged={e => this.handleFilterChange("handoverID", e)}
                      value={handOverID}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className={"display_row align-center valign-middle"}>
                <ButtonGeneral
                    name={Config.lang("DHR_Tim_kiem")}
                    typeButton={"search"}
                    size={"large"}
                    color={"primary"}
                    variant={"outlined"}
                    onClick={this.onFilter}
                />
              </FormGroup>
            </>
          );
        }}
      />
    );
  };

  // renderButtonAction = e => {
  //   return (
  //     <div className="">
  //       <IconButton
  //         aria-label="view"
  //         style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
  //         onClick={() => this.checkStatusEditAndDelete(e)}
  //       >
  //         <EditIcon fontSize="small" />
  //       </IconButton>
  //       <IconButton
  //         aria-label="delete"
  //         style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
  //         onClick={() => this.checkStatusEditAndDelete(e, 0)}
  //       >
  //         <DeleteIcon fontSize="small" />
  //       </IconButton>
  //     </div>
  //   );
  // };

  renderButtonAction = e => {
    return (
        <GridActionBar>
          <div className="">
            <IconButton
              aria-label="view"
              style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
              onClick={() => this.checkStatusEditAndDelete(e)}
            >
              <Icons name={"edit"} />
            </IconButton>
            <IconButton
              aria-label="delete"
              style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
              onClick={() => this.checkStatusEditAndDelete(e, 0)}
            >
              <Icons name={"delete"} />
            </IconButton>
          </div>
        </GridActionBar>
    );
  };

  saveHistory = async (data) => {
    const { AttendanceVoucherID: TransID } = data;
    if (TransID) {
      const _data = {
        AttendanceVoucherName: _.get(data, "AttendanceVoucherName", ""),
        ProjectID: `${_.get(data, "ProjectID", "")} - ${_.get(data, "ProjectName", "")}`,
        HandOverID: `${_.get(data, "HandOverID", "")} - ${_.get(data, "HandOverName", "")}`,
        AttendanceDate: _.get(data, "AttendanceDate", null),
      };

      const captions = {
        AttendanceVoucherName: "DHR_Phieu_cham_cong",
        ProjectID: "DHR_Du_an",
        HandOverID: "DHR_Ban_giao",
        AttendanceDate: "DHR_Ngay_cham_cong",
      };

      const options = {
        data: _data,
        captions: captions,
        action: 3,
        ModuleID: "D29",
        TransID: TransID,
        TransactionID: "W29F2006",
        TransactionName: "Chấm công theo dự án",
      };
      const history = new History(options);
      // const allData = await history.get();
      // history.createDetailHistory("DHR_Nhan_vien", [], [], captions,"key",  null, options ); //Create detail..
      // console.log('test', allData);
      await history.save();
    } else {
      Config.notify.show("error", Config.lang("DHR_Luu_lich_su_khong_thanh_cong"), 2000);
    }

  };

  onChangePage = page => {
    this.paramFilters.skip = page * this.paramFilters.limit;
    this.loadTimekeepingList();
  };

  onChangePerPage = perPage => {
    this.paramFilters.skip = 0;
    this.paramFilters.limit = perPage;
    this.loadTimekeepingList();
  };

  onOrderChanged = (e) => {
    if (!e || !e.column) return false;
    this.paramFilters.fieldSort = e.column.dataField;
    this.paramFilters.typeSort = e.typeSort;
    this.loadTimekeepingList();
  };

  render() {
    const { iPermission, timeKeepingList, selectedRowKeys, loaded } = this.state;
    const { skip, limit } = this.paramFilters;
    if (!iPermission) return null;
    return (
      <FormGroup>
        <ActionToolbar title={Config.lang("DHR_Cham_cong_theo_du_an")}>
          <ButtonGeneral
            name={Config.lang("DHR_Them")}
            typeButton={"add"}
            style={{ textTransform: "uppercase" }}
            size={"large"}
            onClick={() => this.redirectScreen("add")}
          />
        </ActionToolbar>
        <div className={"hidden"}>{this.renderFilters()}</div>
        <FormGroup>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="gird-w29f2005">
              <GridContainer
                itemPerPage={limit}
                skipPerPage={skip}
                onDbCellClick={e => this.redirectScreen("view", e)}
                loading={loaded}
                selectedRowKey={selectedRowKeys}
                style={{ border: "none" }}
                dataSource={_.get(timeKeepingList, "rows")}
                showBorders={false}
                typePaging={"remote"}
                isOrderAPI={true}
                totalItems={_.get(timeKeepingList, "total")}
                showColumnLines={false}
                showRowLines={false}
                hoverStateEnabled={true}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}
                onOrderChanged={this.onOrderChanged}
              >
                <Column
                  caption={Config.lang("DHR_Hanh_dong")}
                  width={110}
                  alignment={"center"}
                  visible={Config.isMobile}
                  cellRender={this.renderButtonAction}
                />
                <Column dataField={"AttendanceVoucherName"} caption={Config.lang("DHR_Phieu_cham_cong")} />
                <Column
                  alignment={"center"}
                  dataField={"AttendanceDate"}
                  dataType={"date"}
                  format={"dd/MM/yyyy"}
                  caption={Config.lang("DHR_Ngay_phieu")}
                />
                <Column dataField={"ProjectName"} caption={Config.lang("DHR_Du_an")} />
                <Column dataField={"HandOverName"} caption={Config.lang("DHR_Ban_giao")} />
                {/*<Column*/}
                {/*  alignment={"center"}*/}
                {/*  width={150}*/}
                {/*  allowFixing={true}*/}
                {/*  visible={!Config.isMobile}*/}
                {/*  cssClass={"grid-action-hover"}*/}
                {/*  cellRender={this.renderButtonAction}*/}
                {/*/>*/}
                <Column
                  alignment={"center"}
                  fixed={true}
                  fixedPosition={"right"}
                  visible={!Config.isMobile}
                  cellRender={this.renderButtonAction}
                />
              </GridContainer>
            </Col>
          </Row>
        </FormGroup>
      </FormGroup>
    );
  }
}
export default compose(
  connect(
    state => ({
      getCboHandOver: state.W29F2005.getCboHandOver,
      getDateByTran: state.W29F2005.getDateByTran
    }),
    dispatch => ({
      generalActions: bindActionCreators(generalActions, dispatch),
      w29f2005Actions: bindActionCreators(W29F2005Actions, dispatch)
    })
  ),
  withStyles(styles)
)(W29F2005);
